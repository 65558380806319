import { useCallback } from 'react'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { NoBscProviderError } from '@binance-chain/bsc-connector'
import {
    NoEthereumProviderError,
    UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import {
    UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
    WalletConnectConnector,
} from '@web3-react/walletconnect-connector'
import { setupNetwork } from './setUpNetwork'
import useToast from './useToast'

const useAuth = () => {
    const { chainId, activate, deactivate, setError } = useWeb3React()
    const { toastError, toastSuccess } = useToast()

    const login = useCallback(
        async(connectorID) => {
            const connector = connectorID

            if (typeof connector !== 'function' && connector) {
                activate(connector, async(error) => {
                    if (error instanceof UnsupportedChainIdError) {
                        setError(error)
                        const provider = await connector.getProvider()
                        const hasSetup = await setupNetwork(provider)
                        if (hasSetup) {
                            activate(connector)
                        }
                    } else {
                        if (error instanceof NoEthereumProviderError || error instanceof NoBscProviderError) {
                            toastError('Provider Error')
                        } else if (
                            error instanceof UserRejectedRequestErrorInjected ||
                            error instanceof UserRejectedRequestErrorWalletConnect
                        ) {
                            if (connector instanceof WalletConnectConnector) {
                                const walletConnector = connector
                                walletConnector.walletConnectProvider = null
                            }
                            toastError('Please authorize to access your account')
                        } else {
                            toastError(error.message)
                        }
                    }
                })
            } else {
                toastError('Unable to find connector')
            }
        }, [activate, setError],
    )

    const logout = useCallback(() => {
        deactivate()
        window.location.reload()
    }, [deactivate, chainId])

    return { login, logout }
}

export default useAuth