import { useWeb3React } from "@web3-react/core";
import { useCallback } from "react";
import { ABI, CONTRACT_ADDRESS, RPC_URL } from "./constss";
import { loadContract } from "./utils";
import Web3 from "web3";
import useToast from "./useToast";

export default function useContract() {
    const { account, active, library, connector } = useWeb3React()
    const { toastError } = useToast()
    const web3 = new Web3(new Web3.providers.HttpProvider(RPC_URL))

    const mint = useCallback(async (price, x, y, landname, charname, charimage) => {
        try {
            const provider = await connector.getProvider()
            let c = loadContract(provider, ABI, CONTRACT_ADDRESS)
            const r = await c.methods.plentblockmint(x, y, `type+${x.toString()}+${y.toString()}`, landname, charname, charimage).send({ from: account, value: price })
            return r
        } catch (e) {
            console.log(e)
            toastError(e.message)
        }
    }, [account])

    const getCordData = useCallback(async (x, y) => {
        try {
            const provider = new Web3(new Web3.providers.HttpProvider(RPC_URL))
            let c = loadContract(provider, ABI, CONTRACT_ADDRESS)
            const r = await c.methods.CheckOwner(x, y).call()
            return { o: r[0], t: r[1], n: r[2][3], ti: r[2][6], cordx: r[2][0], cordy: r[2][1], p: r[2][7], cname: r[2][4], uri: r[2][5] }
        } catch (e) {
            console.log(e)
        }
    }, [account])

    const getUserData = useCallback(async () => {
        try {
            const provider = await connector.getProvider()
            let c = loadContract(provider, ABI, CONTRACT_ADDRESS)
            const r = await c.methods.userallids(account).call()
            return r
        } catch (e) {
            console.log(e)
        }
    }, [account])

    const getCordDataByTokenId = useCallback(async (tid) => {
        try {
            const provider = await connector.getProvider()
            let c = loadContract(provider, ABI, CONTRACT_ADDRESS)
            const r = await c.methods.parseldata(tid).call()
            return r
        } catch (e) {
            console.log(e)
        }
    }, [account])

    const getHistory = useCallback(async () => {
        // try {
        //     const provider = await connector.getProvider()
        //     let c = loadContract(provider, ABI, CONTRACT_ADDRESS)
        //     const r = await c.methods.getHistory(account).call()
        //     return r
        // } catch (e) {
        //     console.log(e)
        // }
    }, [account])

    const getHistoryAll = useCallback(async () => {
        // try {
        //     const provider = new Web3(new Web3.providers.HttpProvider(RPC_URL))
        //     let c = loadContract(provider, ABI, CONTRACT_ADDRESS)
        //     const r = await c.methods.getHistory(CONTRACT_ADDRESS).call()
        //     return r
        // } catch (e) {
        //     console.log(e)
        // }
    }, [])


    const getLatestPrice = useCallback(async () => {
        // try {
        //     const provider = new Web3(new Web3.providers.HttpProvider(RPC_URL))
        //     let c = loadContract(provider, ABI, CONTRACT_ADDRESS)
        //     const r = await c.methods.Getcurrentrate().call()
        //     return r
        // } catch (e) {
        //     console.log(e)
        // }
    }, [])

    const getMessageHash = useCallback(async (price) => {
        try {
            const provider = await connector.getProvider()
            const nonce = await web3.eth.getTransactionCount(account, 'latest')
            let c = loadContract(provider, ABI, CONTRACT_ADDRESS)
            const r = await c.methods.getMessageHash(CONTRACT_ADDRESS, price, nonce).call()
            return r
        } catch (e) {
            console.log(e)
        }
    }, [active])

    const multiMint = useCallback(async (price, xarr, yarr, sign, countryname) => {
        try {
            const provider = await connector.getProvider()
            const nonce = await web3.eth.getTransactionCount(account, 'latest');
            let c = loadContract(provider, ABI, CONTRACT_ADDRESS)
            const r = await c.methods.multibuyparsel(nonce, sign, xarr, yarr, countryname).send({ from: account, value: price })
            return r
        } catch (e) {
            toastError(e.message)
        }
    }, [account])

    const getStartTime = useCallback(async () => {
        // try {
        //     const provider = new Web3(new Web3.providers.HttpProvider(RPC_URL))
        //     let c = loadContract(provider, ABI, CONTRACT_ADDRESS)
        //     const r = await c.methods.starttime().call()
        //     return r
        // } catch (e) {
        //     console.log(e)
        // }
    }, [])

    const getAafterchangerate = useCallback(async () => {
        // try {
        //     const provider = new Web3(new Web3.providers.HttpProvider(RPC_URL))
        //     let c = loadContract(provider, ABI, CONTRACT_ADDRESS)
        //     const r = await c.methods.aafterchangerate().call()
        //     return r
        // } catch (e) {
        //     console.log(e)
        // }
    }, [])

    const getUsd = useCallback(async () => {
        // try {
        //     const provider = new Web3(new Web3.providers.HttpProvider(RPC_URL))
        //     let c = loadContract(provider, ABI, CONTRACT_ADDRESS)
        //     const r = await c.methods.usd().call()
        //     return r
        // } catch (e) {
        //     console.log(e)
        // }
    }, [])

    const getIncrementAmount = useCallback(async () => {
        // try {
        //     const provider = new Web3(new Web3.providers.HttpProvider(RPC_URL))
        //     let c = loadContract(provider, ABI, CONTRACT_ADDRESS)
        //     const r = await c.methods.incrementAmount().call()
        //     return r
        // } catch (e) {
        //     console.log(e)
        // }
    }, [])

    const getCityData = useCallback(async (cname) => {
        try {
            const provider = new Web3(new Web3.providers.HttpProvider(RPC_URL))
            let c = loadContract(provider, ABI, CONTRACT_ADDRESS)
            const r = await c.methods.GetData(cname).call()
            return r
        } catch (e) {
            console.log(e)
        }
    }, [])

    const getPlusValue = useCallback(async () => {
        // try {
        //     const provider = new Web3(new Web3.providers.HttpProvider(RPC_URL))
        //     let c = loadContract(provider, ABI, CONTRACT_ADDRESS)
        //     const r = await c.methods.usd().call()
        //     return r
        // } catch (e) {
        //     console.log(e)
        // }
    }, [])

    const getErrorAmount = useCallback(async () => {
        try {
            const provider = new Web3(new Web3.providers.HttpProvider(RPC_URL))
            let c = loadContract(provider, ABI, CONTRACT_ADDRESS)
            const r = await c.methods.TXFEE().call()
            return r
        } catch (e) {
            console.log(e)
        }
    }, [])




    return {
        mint,
        getCordData,
        getUserData,
        getCordDataByTokenId,
        getHistory,
        getHistoryAll,
        getLatestPrice,
        multiMint,
        getMessageHash,
        getStartTime,
        getAafterchangerate,
        getUsd,
        getIncrementAmount,
        getCityData,
        getPlusValue,
        getErrorAmount
    }
}