import Cookies from 'universal-cookie';

const cookies = new Cookies();
const COOKIE_OPTIONS = {
      path: '/',    // access through out project
      expires: new Date(Date.now() + 1 * 24 * 60 * 60 * 1000),    // 24 hours
      // sameSite: 'strict',
      // secure: true,
      // domain: ''
  }

export const setCookie = (key, value) => {    // set cookie
  cookies.set(key, value, COOKIE_OPTIONS);
}

export const getCookie = (key) => {   // get single cookie
  return cookies.get(key);
}

export const removeCookie = (key) => {    // remove single cookie
  cookies.remove(key, COOKIE_OPTIONS);
}

export const clearCookie = () => {    // clear cookie
  const allCookies = cookies.getAll();
  for (const key in allCookies) {   // remove one by one
    cookies.remove(key, COOKIE_OPTIONS);
  }
}