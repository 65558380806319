import './assets/css/font.css';
import './App.css';
import { BrowserRouter, Routes, Route, Outlet, Navigate } from 'react-router-dom';
import Home from './page/Home';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import ParcelList from './page/ParcelList';
import { Toaster } from 'react-hot-toast';
import CreateLand from './page/CreateLand';
import Success from './page/Success';
import CityList from './page/CityList';
import { getCookie } from './commponet/Cookie';
import AdminHome from './page/AdminHome';
import AdminLogin from './page/AdminLogin';

const getLibrary = (provider) => {
  const library = new Web3Provider(provider);
  return library;
}

const PrivateRoute = () => {
  if (getCookie('celeb-admin')) {
    return <Outlet />
  } else {
    return <Navigate to={'/admin/login'} />
  }
}

const PublicRoute = () => {
  if (!getCookie('celeb-admin')) {
    return <Outlet />
  } else {
    return <Navigate to={'/admin'} />
  }
}

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path="/admin" element={<AdminHome />} />
          </Route>
          <Route element={<PublicRoute />} >
            <Route path='/' element={<Home />} />
            <Route path='/map' element={<Home />} />
            <Route path='/parcellist' element={<ParcelList />} />
            <Route path="/admin/login" element={<AdminLogin />} />
          </Route>
          {/* <Route path="/createland" element={<CreateLand />} /> */}
          {/* <Route path="/success" element={<Success />} /> */}
          {/* <Route path="/citylist" element={<CityList />} /> */}
        </Routes>
      </BrowserRouter>
      <Toaster position="top-right" toastOptions={{ duration: 2500 }} />
    </Web3ReactProvider>
  )
}

export default App;