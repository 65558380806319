import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Header from "../commponet/Header";
import useContract from "../useContract";
import CircularProgress from '@mui/material/CircularProgress'
import Footer from "../commponet/Footer";
import axios from "axios";
import map1 from "../assets/img/background/map-1.png"
import '../assets/css/parcel-cards.css';
import { BLOCK_EXPLORE_URL, CONTRACT_ADDRESS } from "../constss";
import useToast from "../useToast";

export default function ParcelList() {

  const { getUserData, getCordDataByTokenId } = useContract();
  const { active, account } = useWeb3React();

  const [parcelData, setParcelData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { toastError, toastSuccess } = useToast();
  const navigate = useNavigate()

  useEffect(() => {
    if (!active) {
      navigate('/')
    }
  }, [active])

  const getParceles = async () => {
    if (account) {
      try {
        setLoading(true)
        const response = await axios.post('https://backend.celebverse.live/get_parcel_data_byaddress', {
          "address": account
        })
        if (response.data.status == 'Success') {
          setParcelData(response.data.result);
        } else {
          setParcelData([]);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    } else {
      toastError('Please connect your wallet')
    }
  }

  useEffect(() => {
    if (active) {
      getParceles();
    }
  }, [active])

  return (
    <>
      <Header />
      <div className="pt-5 page_height parcel_page">
        <div className="container">
          <div className="referral_title mb-md-5 mb-3">
            <h2 className="text-light">My Parcels</h2>
            {/* <p className="text-dark">But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system.</p> */}
          </div>
          <div className="row justify-content-start">

            {
              parcelData ?
                parcelData.length ?
                  parcelData.map((item, index) => {
                    return (
                      <div className="col-12 col-md-6 col-lg-4 col-xl-3" key={index}>
                        <div className="card  parcel-card">
                          <div className="card-body card_content mb-2 py-5 px-md-3">
                            <div className="d-flex align-items-center">
                              <div className="text-white fw-bold">
                                <p className="form_lable">Date: </p>
                              </div>
                              <div className="text-white ml-2 fw-bold">
                                <p className="ml-3">&nbsp; {item.ondate}</p>
                              </div>
                            </div>
                            <div className="mb-3">
                              <label for="exampleInputEmail1" className="form-label form_lable fw-bold">Token ID :</label>
                              {/* <input type="text" className="form-control form_input shadow-none text-center" value={item.tokenid ? item.tokenid : '-'} readOnly={true} /> */}
                              <label className="form-control form_input shadow-none text-center">
                                <a className="text-white" target={'_blank'} href={`${BLOCK_EXPLORE_URL}/token/${CONTRACT_ADDRESS}?a=${item.tokenid ? item.tokenid : ''}`}>{item.tokenid ? item.tokenid : '-'}</a>
                              </label>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="fw-bold">
                                <span className="form_lable"> X-axis :</span>
                                <span className="text-white">&nbsp; {item.x_axis} </span>
                              </p>
                              <p className="fw-bold">
                                <span className="form_lable"> Y-axis :</span>
                                <span className="text-white">&nbsp; {item.y_axis} </span>
                              </p>
                            </div>
                            <div className="mb-3">
                              <label for="exampleInputEmail1" className="form-label form_lable fw-bold">Amount :</label>
                              <input type="text" className="form-control form_input shadow-none text-center" value={'$' + item.usdamount ? item.usdamount : '0'} readOnly={true} />

                            </div>
                            {
                              item.transhash ?
                                (<div className="fw-bold" >
                                  <p className="form_lable mb-0">Tran.Hash :</p>
                                  <p className="text-white"><a className="text-white" href={`${BLOCK_EXPLORE_URL}/tx/${item.transhash}`} target={'_blank'}>{
                                    item.transhash.substring(0, 5) + '...' + item.transhash.substring(item.transhash.length - 5, item.transhash.length)
                                  }</a></p>
                                </div>)
                                :
                                ''
                            }

                          </div>

                        </div>
                      </div>
                    )
                  })
                  :
                  <div className="col-12 no_found">No Data Found</div>
                :
                <div className="col-12 no_found"><CircularProgress style={{ color: '#ffffff' }} /></div>
            }
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}