import React, { useEffect, useState } from 'react'
import './../App.css';
import { useRef } from 'react';
import axios from 'axios';
import { useWeb3React } from "@web3-react/core";
import Draggable from "react-draggable";
import { Link, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress'
import { loadContract, shortenAddress } from '../utils';
import LoadingOverlay from "react-loading-overlay";
import styled, { css } from "styled-components";
import useToast from '../useToast';
import Web3 from 'web3';
import { ABI, APPROVE_CONTRACT_ABI, APPROVE_CONTRACT_ADDRESS, CONTRACT_ADDRESS, ROOT_URL, RPC_URL } from '../constss';

const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${props =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;

function Map({ bnbUsdPrice }) {
  const canvasDimension = 4000;
  let myCanvasData = [];
  let splitData = [];
  const universeQuadrantLength = canvasDimension / 10 / 2;
  const splitSize = 4000;
  const boxSize = 10;
  const centerBoxColor = 'red';
  let selectedData = {}
  // let selectedCoords = []
  const { active } = useWeb3React()
  const roadColor = '#47484c';
  const waterColor = '#6DCAE4';
  const desertColor = '#FF791C';
  const publicPlaceColor = '#d0f0c0';
  const currentSelectedColor = '#F5F5F5';
  let canvas;
  const [historyData, setHistoryData] = useState([])
  const [historyDataAll, setHistoryDataAll] = useState([])
  const [mintTx, setMintTx] = useState()
  const [selectedCoords, setSelectedCoords] = useState([])
  const [data, setData] = useState([])
  const [popHandler, setPopHandler] = useState(0)
  const [selectedMinted, setSelectedminted] = useState([]);
  const [screenLoading, setScreenLoading] = useState(false)
  const [dragStatus, setDragStatus] = useState("")
  const [tab, setTab] = useState(false)
  const [applyCoupen, setApplyCoupen] = useState(false);
  const [coupenValue, setCoupenValue] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);
  const [currentTime, setCurrentTime] = useState('');
  const [signature, setSignature] = useState('');
  const [countryName, setCountryName] = useState('celebverse');
  const [loadingBuy, setLoadingBuy] = useState(false);
  const [ownerInfo, setOwnerInfo] = useState([]);
  const [coupenValid, setCoupenValid] = useState('');
  const [coupenMessage, setCoupenMessage] = useState('');
  const [loadingCoupenCode, setLoadingCoupenCode] = useState(false);
  const [prevCoord, setPrevCoord] = useState({});
  const [bnbPrice, setBnbPrice] = useState('');
  const canvasRef = useRef();

  const { account, connector } = useWeb3React();
  const { toastError, toastSuccess } = useToast();

  const getWholeUniverse = async () => {
    canvas = document.getElementById("canvas-grid");
    drawBlockchainGrid()
    data.forEach((object) => {
      changeColorOfBox(object.x, object.y, object.color);
    });

  }

  const desiredToRealCoords = (x, y) => {
    canvas = document.getElementById("canvas-grid");
    const ratio = canvas.getBoundingClientRect().width / 2 / boxSize;
    if (y !== 0) y *= -1;
    x += ratio;
    y += ratio;

    if (x !== 0) x *= boxSize;
    if (y !== 0) y *= boxSize;

    x += 1;
    y += 1;

    return { x, y };
  }

  const changeColorOfBox = (x, y, color) => {
    const desiredCoords = desiredToRealCoords(x, y);
    canvas.getContext("2d").fillStyle = color;
    canvas.getContext("2d").fillRect(
      desiredCoords.x,
      desiredCoords.y,
      boxSize - 2,
      boxSize - 2
    );
  }

  const drawBlockchainGrid = () => {
    for (let x = 0; x <= canvasDimension + 1; x += boxSize) {
      canvas.getContext("2d").moveTo(x, 0);
      canvas.getContext("2d").lineTo(x, canvasDimension);
    }

    for (let y = 0; y <= canvasDimension + 1; y += boxSize) {
      canvas.getContext("2d").moveTo(0, y);
      canvas.getContext("2d").lineTo(canvasDimension, y);
    }

    canvas.getContext("2d").stroke();
    changeColorOfBox(0, 0, centerBoxColor);
  }

  const realToDesiredCoords = (x, y) => {
    canvas = document.getElementById("canvas-grid");
    const ratio = canvas.getBoundingClientRect().width / 2 / boxSize;
    x -= 1;
    y -= 1;

    if (x !== 0) x /= boxSize;
    if (y !== 0) y /= boxSize;

    x -= ratio;
    y -= ratio;

    if (y !== 0) y *= -1;

    return { x, y };
  }

  const getBoxCoords = (event) => {
    canvas = document.getElementById("canvas-grid");
    const rect = canvas.getBoundingClientRect();
    let clientX = event.which === 1 ? event.clientX : event.changedTouches[0].clientX
    let clientY = event.which === 1 ? event.clientY : event.changedTouches[0].clientY
    return {
      x:
        1 +
        (clientX - rect.left) -
        ((clientX - rect.left) % boxSize),
      y:
        1 +
        (clientY - rect.top) -
        ((clientY - rect.top) % boxSize),
    };
  }

  const selectForMint = (x, y) => {
    if (loadingBuy) {
      return;
    }

    getOwnerInfo(x, y);
    let findNotForSell = data.find(e => (e.color == '#ff5643' || e.color == '#67676A' || e.color == '#51AAD8' || e.color == '#5FC874' || e.color == '#CFB280') && e.x == x && e.y == y);

    if (!findNotForSell) {
      setPrevCoord(data.find(e => e.x == x && e.y == y))
      setApplyCoupen(false);
      setCoupenMessage('');
      setTotalPrice(0);
      setCoupenValue('');
      getTotalPrice(x, y);
      const find = selectedCoords.find(e => e.xCoord == parseInt(x) && e.yCoord == parseInt(y));
      setTab(true);
      setPopHandler(0);
      if (find) {   // remove
        let arr = [...selectedCoords];
        var index = arr.findIndex(e => e.xCoord == parseInt(x) && e.yCoord == parseInt(y));
        arr.splice(index, 1);
        changeColorOfBox(x, y, prevCoord.color);
        setSelectedCoords(arr);
      } else {  // add
        if (selectedCoords.length) {  // ONLY SINGLE PARCEL SELECT
          changeColorOfBox(selectedCoords[0].xCoord, selectedCoords[0].yCoord, prevCoord.color);
        }
        changeColorOfBox(x, y, currentSelectedColor);
        setSelectedCoords([{ xCoord: parseInt(x), yCoord: parseInt(y) }]);
      }
    }


  };

  useEffect(() => {
    if (!selectedCoords.length) {
      setTab(false)
    }
  }, [selectedCoords])

  const onDragStop = (event) => {
    if (dragStatus !== 'dragging' && active) {
      const coords = getBoxCoords(event);
      const realCoords = realToDesiredCoords(coords.x, coords.y);
      selectedData.x1 = realCoords.x;
      selectedData.y1 = realCoords.y;
      selectForMint(selectedData.x1, selectedData.y1)
    } else if (!active && dragStatus !== 'dragging') {
      toastError('Please connect your wallet')
    }
  }

  useEffect(() => {
    getWholeUniverse()
  }, [data])

  const getLandData = async () => {
    try {
      setScreenLoading(true)
      const response = await axios.get(`https://backend.celebverse.live/get_land_data`)
      if (response.data.status == "success") {
        setData(response.data.result)
      } else {
        setData([]);
      }
      setScreenLoading(false)
    } catch (e) {
      setScreenLoading(false)
      console.log('Error.land_data', e)
    }
  }

  const getMintedParcels = async () => {
    try {
      const response = await axios.get(`${ROOT_URL}/get_mint_All_parcel_data`)
      if (response.data.status == "Success") {
        setHistoryData(response.data.result)
      } else {
        setHistoryData([]);
      }
    } catch (e) {
      console.log('Error.minted_data', e)
    }
  }
  useEffect(() => {
    if (active) {
      getMintedParcels();
    }
  }, [active, mintTx])

  useEffect(() => {
    getLandData()
  }, [])

  const onCloseCart = () => {
    if (loadingBuy) {
      return;
    }
    setLoadingCoupenCode(false);
    setApplyCoupen(false);
    setCoupenMessage('');
    setTotalPrice(0);
    setCoupenValue('');
    setTab(false);
    setCoupenMessage('');
    setSelectedCoords([]);
    setPrevCoord({});
    for (var i = 0; i < selectedCoords.length; i++) {
      changeColorOfBox(selectedCoords[0].xCoord, selectedCoords[0].yCoord, prevCoord.color);
      changeColorOfBox(selectedCoords[i].xCoord, selectedCoords[i].yCoord, prevCoord.color)
    }
  }

  const convertPrice = (value) => {
    const price = (value * 10 ** 18).toLocaleString("fullwide", { useGrouping: false })
    return price
  }

  const getOwnerInfo = async (x, y) => {
    if (loadingBuy) {
      return;
    }
    setOwnerInfo([]);
    const res_is_minted = await axios.post(`${ROOT_URL}/get_address_by_cordinate`,
      {
        "xaxis": x,
        "yaxis": y
      }
    )
    if (res_is_minted.data.status == 'Success') {
      if (res_is_minted.data.result.length) {
        setTab(true);
        setPopHandler(1);
        setOwnerInfo([res_is_minted.data.result[0], x, y]);
      }
    }
  }

  const getTotalPrice = async (x, y) => {
    if (loadingBuy) {
      return;
    }
    if (applyCoupen) {
      setLoadingCoupenCode(true);
    }
    setTotalPrice(0);

    const res_check_coupen = await axios.post(`${ROOT_URL}/check_coupen_code`, { coupencode: coupenValue });
    if (res_check_coupen.data.status == 'Success') {
      setCoupenValid(res_check_coupen.data.valid);
      setCoupenMessage(res_check_coupen.data.msg);
      const res = await axios.post(`${ROOT_URL}/check_buy_land`,
        {
          "xaxis": x,
          "yaxis": y,
          "coupencode": res_check_coupen.data.valid == 'VALID' ? coupenValue : ''
        }
      )

      if (res.data.status == 'Success') {
        setTotalPrice(res.data.result.amount);
        // setCurrentTime(res.data.result.currenttime);
        // setSignature(res.data.result.signature);
        setLoadingCoupenCode(false);
        // setCoupenValid(res.data.result.valid);
        // if (res.data.result.coupenvalid) {
        //   setCoupenMessage(res.data.result.coupenvalid);
        // }
      } else {
        setLoadingCoupenCode(false);
        if (res.data.result) {
          setTotalPrice(res.data.result.amount);
          setCurrentTime(res.data.result.currenttime);
          setSignature(res.data.result.signature);
          // setCoupenValid(res.data.result.valid);
          // setCoupenMessage(res.data.result.coupenvalid);
        }
        if (res.data.msg) {
          toastError(res.data.msg);
        }
      }

    }

  }

  const onBuyNow = async () => {
    if (account) {
      if (loadingBuy || loadingCoupenCode) {
        return;
      }
      if (coupenValid != 'VALID') {
        setApplyCoupen(false);
      }
      setLoadingBuy(true);
      try {
        const provider = await connector.getProvider()
        // let load_approve = loadContract(provider, APPROVE_CONTRACT_ABI, APPROVE_CONTRACT_ADDRESS);
        // const res_approve = await load_approve.methods.approve(CONTRACT_ADDRESS, convertPrice(totalPrice)).send({ from: account });
        const res_sign = await axios.post(`${ROOT_URL}/send_signature`,
          {
            "xaxis": selectedCoords[0].xCoord,
            "yaxis": selectedCoords[0].yCoord,
            "coupencode": coupenValid == 'VALID' ? coupenValue : '',
            "amount": convertPrice(bnbPrice)
          }
        )

        const load_buy = loadContract(provider, ABI, CONTRACT_ADDRESS);
        const res_buy = await load_buy.methods.multibuyparsel(
          convertPrice(bnbPrice),
          res_sign.data.currenttime,
          coupenValid == 'VALID' ? coupenValue : '',
          res_sign.data.signature,
          selectedCoords[0].xCoord,
          selectedCoords[0].yCoord,
          countryName,
          totalPrice
        ).send({ from: account, value: convertPrice(bnbPrice) });
        try {
          const res_api = await axios.post(`${ROOT_URL}/buy_land_entry`,
            {
              "tranhash": res_buy.transactionHash,
              "address": account,
              "xaxis": selectedCoords[0].xCoord,
              "yaxis": selectedCoords[0].yCoord,
              "amount": bnbPrice,
              "usdtamt": totalPrice,
              "tokenid": res_buy.events.onMint.returnValues.TokenId,
              "coupencode": coupenValid == 'VALID' ? coupenValue : '',
              "iscelebverse": 1
            }
          )
        } catch (err) {
          console.log('Error.API-buyland', err);
        }
        setLoadingBuy(false);
        setSelectedCoords([]);
        setPrevCoord({});
        setCoupenValue('');
        setApplyCoupen(false);
        setTab(false)
        toastSuccess('Parcel buy successfully');
        const find = data.find(e => e.x == selectedCoords[0].xCoord && e.y == selectedCoords[0].yCoord)
        setData([...data, { ...find, color: '#ff5643' }])
      } catch (e) {
        setLoadingBuy(false);
        toastError(e.message)
        if (window.innerWidth <= 991) {
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
        console.log('Error.buy-land', e);
      }
    } else {
      toastError('Please connect your wallet');
    }
  }

  useEffect(() => {
    axios.get('https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT').then(res => {
      if (res.data) {
        const price = (totalPrice / res.data.price).toFixed(8);
        setBnbPrice(price);
      }
    })
  }, [totalPrice]);

  return (
    <>
      <LoadingOverlay spinner={true} active={screenLoading} className="d-flex align-items-center justify-content-center">
        <div className="">
          <div id="container" className='canvas-container'>
            <React.StrictMode>
              <Draggable
                onStart={() => setDragStatus("drag-start")}
                onStop={onDragStop}
                onDrag={() => setDragStatus("dragging")}
              >
                <canvas
                  style={{ cursor: 'grabbing' }}
                  ref={canvasRef}
                  id="canvas-grid"
                  width="4000"
                  height="4000"

                ></canvas>
              </Draggable>

            </React.StrictMode>
          </div>
          <div className={tab ? 'multibox d-block' : 'multibox d-none'}>
            {popHandler == 0 && (
              <div className="usercard m-0">
                <div className="card position-relative card_shadow">
                  <div className="card-body p-2 bg-transparent">
                    <Link className="cancel_btn" to={""} onClick={() => {
                      onCloseCart()
                    }}> <img src="/assets/img/close.svg" /> </Link>
                    <div className="row">
                      <div className='col-12 mb-3'>
                        <div className='d-flex popup_title justify-content-between align-items-center'>
                          <h3>Selected Parcel</h3>
                          {/* <p>Total Parcel : {selectedCoords.length}</p> */}
                        </div>
                      </div>
                      <div className='col-12 mb-3'>
                        <div className='box_scroll'>
                          {selectedCoords.map((item, index) => {
                            return (
                              <div className='simple_card p-3 py-2 mt-1' key={index}>
                                <div className="d-flex justify-content-between align-items-center ">
                                  <p className='m-0'>
                                    <img src='/assets/img/location.svg' className='location ' />
                                    {item.xCoord}, {item.yCoord}
                                  </p>
                                  <p className='m-0' style={{ cursor: 'pointer' }}>
                                    {/* {100} USD */}
                                    <img src='/assets/img/delet.svg' className='deleted' onClick={() => selectForMint(item.xCoord, item.yCoord)} />
                                  </p>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div className='d-flex justify-content-end'>
                        {
                          applyCoupen ?
                            <>
                              <input
                                type={'text'}
                                placeholder={'XXXXXX'}
                                className="coune_code me-2"
                                maxLength={6}
                                value={coupenValue}
                                onChange={(event) => { setCoupenValue(event.target.value); setCoupenMessage('') }}
                              />
                              {
                                loadingCoupenCode ?
                                  <button className="wallet_btn">
                                    <CircularProgress className='mx-3' size={19} style={{ color: '#ffffff' }} />
                                  </button>
                                  :
                                  <button className="wallet_btn" style={{ cursor: 'pointer', }} onClick={() => getTotalPrice(selectedCoords[0].xCoord, selectedCoords[0].yCoord)}>
                                    Apply
                                  </button>
                              }
                            </>
                            :
                            <button className="wallet_btn" style={{ cursor: 'pointer', }} onClick={() => { setApplyCoupen(true); setCoupenValue(''); setCoupenMessage('') }}>
                              Add Coupon
                            </button>
                        }
                      </div>
                      {applyCoupen && coupenValue && <div className={coupenValid == 'VALID' ? 'success_msg' : 'error_mess'}>{coupenMessage}</div>}
                    </div>
                    <hr />
                    <div className='row mt-3 align-items-center'>
                      <div className="col-7  mb-4 mb-md-0">
                        <div className="property text-center text-md-start m-0">
                          {/* <div className='discount'>
                            <p>Price : <strong>$ 100</strong></p>
                            <p>Discount : <strong>- $ 10</strong></p>
                          </div> */}
                          <p className='color_orange'>Total Price</p>
                          <h3 className='price_lable'>{bnbPrice > 0 ? bnbPrice : '-'} BNB</h3>
                          <h5 className='prise_doller'>${totalPrice ? totalPrice : '-'}</h5>
                        </div>
                      </div>
                      <div className="col-5 mb-4 mb-md-0 ">
                        <div className=" align-items-center property text-center text-md-end">
                          {
                            loadingBuy ?
                              <button className="wallet_btn">
                                <CircularProgress className='me-2 mt-1' size={14} style={{ color: '#ffffff' }} />Loading...
                              </button>
                              :
                              <button className="wallet_btn" style={{ cursor: 'pointer', }} onClick={() => onBuyNow()} disabled={!totalPrice}>
                                Buy Now
                                <svg className="ms-2" xmlns="http://www.w3.org/2000/svg" width="6.755" height="10.681" viewBox="0 0 6.755 10.681">
                                  <path id="Path_2712" data-name="Path 2712" d="M547.032,1016.226l4.633,4.633-4.633,4.633" transform="translate(-546.325 -1015.519)" fill="none" stroke="#fff" stroke-width="2" />
                                </svg>
                              </button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {popHandler == 1 && (
              <div className="usercard m-0">
                <div className="card position-relative card_shadow">
                  <div className="card-body p-2 bg-transparent">
                    <Link className="cancel_btn" to={""} onClick={() => setTab(false)}> <img src="/assets/img/close.svg" /> </Link>
                    <div className="row">
                      <div className='col-12 mb-3'>
                        <div className='d-flex popup_title justify-content-between align-items-center'>
                          <h3>Parcel Detail</h3>
                          {/* <p>Total Parcels : 12</p> */}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">

                          <div className="d-flex justify-content-between align-items-center property">
                            <span>Owner</span>
                            <p className="text-decoration-underline">
                              {ownerInfo.length ? shortenAddress(ownerInfo[0].walletaddress) : 'Loading...'}
                            </p>
                          </div>

                        </div>
                        <div className="col-lg-12">
                          <div className="d-flex justify-content-between align-items-center property">
                            <span>Location</span>
                            <p className="text-decoration-underline">{ownerInfo[1]} , {ownerInfo[2]}</p>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          </div>
        </div>
      </LoadingOverlay>
    </>
  );
}

export default Map;