import { CircularProgress } from '@mui/material';
import { useWeb3React } from '@web3-react/core';
import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { clearCookie, getCookie } from '../commponet/Cookie';
import { ROOT_URL } from '../constss';
import useAuth from '../userAuth';
import useToast from '../useToast';
import { injected, shortenAddress, walletconnect } from '../utils';
import CompletedRecord from './CompletedRecord';
import MintedRecoeds from './MintedRecoeds';
import PendingRecord from './PendingRecord';

export default function AdminHome() {

  const [activeTab, setActiveTab] = useState(0);
  const [record, setRecord] = useState({ x: '', y: '', price: '', address: '' });
  const [validation, setValidation] = useState({ x: '', y: '', price: '', address: '' });
  const [pendingRecords, setPendinRecords] = useState(null);
  const [completedRecords, setCompletedRecords] = useState(null);
  const [mintedRecords, setMintedRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const { toastError, toastSuccess } = useToast();
  const { account, active, connector, deactivate } = useWeb3React();
  const navigate = useNavigate();
  const { login } = useAuth();

  useEffect(() => {   // CHECK RIGHT ADMIN
    if (account) {
      if (account != '0x3e0595C102E894DAC9b7b85C952A819da86B82AD') {
        toastError('Wrong admin, Please change account and try again');
        deactivate();
        clearCookie();
        navigate('/admin/login');
      }
    }
  }, [account]);

  const authenticate = async (connector) => {
    await login(connector)
    document.getElementById('connect_wallet_admin_close').click();
  }

  const getPendingList = () => {
    axios({
      method: 'get',
      url: `${ROOT_URL}/predefine_pending_data`,
    }).then(res => {
      // console.log('GET pending list res ', res);
      if (res.data.status == 'Success') {
        setPendinRecords(res.data.result);
      } else {
        setPendinRecords([]);
      }
    }
    ).catch(err => console.log('Error.admin-get-pending-rec', err));
  }

  const getCompletedList = () => {
    axios({
      method: 'get',
      url: `${ROOT_URL}/predefine_complete_data`,
    }).then(res => {
      // console.log('GET complete list res ', res);
      if (res.data.status == 'Success') {
        setCompletedRecords(res.data.result);
      } else {
        setCompletedRecords([]);
      }
    }
    ).catch(err => console.log('Error.admin-get-completed-rec', err));
  }

  const getMintedList = () => {
    axios({
      method: 'get',
      url: `${ROOT_URL}/get_mint_All_parcel_data`,
    }).then(res => {
      // console.log('GET minted list res ', res);
      if (res.data.status == 'Success') {
        setMintedRecords(res.data.result);
      } else {
        setMintedRecords([]);
      }
    }
    ).catch(err => console.log('Error.admin-get-completed-rec', err));
  }

  useEffect(() => {
    if (activeTab == 0) {
      getPendingList();
    }
    if (activeTab == 1) {
      getCompletedList();
    }
    if (activeTab == 2) {
      getMintedList();
    }
  }, [activeTab]);

  const onClickAddRecord = () => {
    if (!account) {
      toastError('Please connect your wallet');
      return;
    }
    if (!record.x) {
      setValidation({ ...validation, x: true });
      return
    }
    if (!record.y) {
      setValidation({ ...validation, y: true });
      return
    }
    if (!record.price) {
      setValidation({ ...validation, price: true });
      return
    }
    if (!record.address) {
      setValidation({ ...validation, address: true });
      return
    }
    setLoading(true);
    axios({
      method: 'post',
      url: `${ROOT_URL}/predefine_data_enter`,
      data: {
        xaxis: record.x,
        yaxis: record.y,
        address: record.address,
        amount: record.price
      }
    }).then(res => {
      setLoading(false);
      // console.log('GET insert list res ', res);
      if (res.data.status == 'Success') {
        getPendingList();
        setRecord({ x: '', y: '', price: '', address: '' });
        toastSuccess(res.data.msg);
      } else {
        toastError(res.data.msg);
      }
    }
    ).catch(err => {
      setLoading(false);
      console.log('Error.admin-get-pending-rec', err)
    });
  }

  return (
    <div>
      <div className="header position-relative">
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              <div className=" navbar-collapse d-flex align-items-center justify-content-between">
                <div className="nav_flex">
                  <Link to={"/admin"} className="navbar-brand me-1">
                    <img src={"/assets/img/logo/logo.svg"} alt="logo" />
                  </Link>
                </div>
              </div>
              <div className="wallet_connect ms-lg-4 mt-md-2 mt-lg-0">
                <button
                  className="wallet_btn py-2 me-2"
                  data-bs-toggle="modal"
                  data-bs-target={account ? '' : "#connect_wallet_admin_modal"}
                >
                  {active ? shortenAddress(account) : "Wallet Connect"}
                </button>
              </div>
              <button className="wallet_btn py-2" onClick={() => { clearCookie(); navigate('/') }}>Logout</button>
            </div>
          </nav>
        </div>
      </div>
      <div className="page_height parcel_page admin_height">
        <div className="container">
          <div className='admin_add_rec'>
            <div className="admin_add_re_inner">
              <input
                type={'text'}
                placeholder={'X-Coord'}
                className="coune_code admin_input py-2 pe-3 me-3"
                style={validation.x ? { borderColor: '#ff4314' } : {}}
                value={record.x}
                onChange={(event) => {
                  if (/^[0-9-]+$|^$/.test(event.target.value)) {
                    setRecord({ ...record, x: event.target.value });
                    setValidation({ ...validation, x: false });
                  }
                }}
              />
              <input
                type={'text'}
                placeholder={'Y-Coord'}
                className="coune_code admin_input py-2 pe-3 me-3"
                style={validation.y ? { borderColor: '#ff4314' } : {}}
                value={record.y}
                onChange={(event) => {
                  if (/^[0-9-]+$|^$/.test(event.target.value)) {
                    setRecord({ ...record, y: event.target.value });
                    setValidation({ ...validation, y: false });
                  }
                }}
              />
              <input
                type={'text'}
                placeholder={'Price ($)'}
                className="coune_code admin_input py-2 pe-3 me-3"
                style={validation.price ? { borderColor: '#ff4314' } : {}}
                value={record.price}
                onChange={(event) => {
                  if (/^[0-9]+$|^$/.test(event.target.value)) {
                    setRecord({ ...record, price: event.target.value });
                    setValidation({ ...validation, price: false });
                  }
                }}
              />
              <input
                type={'text'}
                placeholder={'Wallet Address'}
                className="coune_code admin_input py-2 pe-3 me-3"
                style={validation.address ? { borderColor: '#ff4314' } : {}}
                value={record.address}
                onChange={(event) => { setRecord({ ...record, address: event.target.value }); setValidation({ ...validation, address: false }); }}
              />
              <button className="wallet_btn" onClick={() => onClickAddRecord()}>
                {
                  loading ?
                    <div className='d-flex align-items-center'><CircularProgress className='mx-2' size={16} style={{ color: '#ffffff' }} />Loading</div>
                    :
                    'Add Record'
                }
              </button>
            </div>
          </div>
          <div className='tab_list'>
            <ul className="nav mb-0">
              <li className="nav-item">
                <a className={activeTab == 0 ? "nav-link ms-0 tab active" : "nav-link ms-0 tab"} onClick={() => setActiveTab(0)}>Pending</a>
              </li>
              <li className="nav-item">
                <a className={activeTab == 1 ? "nav-link tab active" : "nav-link tab"} onClick={() => setActiveTab(1)}>Completed</a>
              </li>
              <li className="nav-item">
                <a className={activeTab == 2 ? "nav-link tab active" : "nav-link tab"} onClick={() => setActiveTab(2)}>Minted</a>
              </li>
            </ul>
          </div>
          {
            activeTab == 0 && <PendingRecord pendingRecords={pendingRecords} />
          }
          {
            activeTab == 1 && <CompletedRecord completedRecords={completedRecords} />
          }
          {
            activeTab == 2 && <MintedRecoeds mintedRecords={mintedRecords} />
          }
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="connect_wallet_admin_modal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="connect_wallet_admin_modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered login_model">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_wallet_admin_modalLabel">
                Wallet Connect
              </h5>
              <button
                id='connect_wallet_admin_close'
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="align-items-center d-flex justify-content-between wallet_box" onClick={() => authenticate(injected)}>
                <div className="align-items-center d-flex wallet">
                  <img src="/assets/img/icon/meta.svg" />
                  <span>Metamask</span>
                </div>
                <div className="right-arrow">
                  <img src="/assets/img/icon/arrow.svg" />
                </div>
              </div>
              <div className="align-items-center d-flex justify-content-between wallet_box" onClick={() => authenticate(walletconnect)}>
                <div className="align-items-center d-flex wallet">
                  <img src="/assets/img/icon/wallet.svg" />
                  <span>Wallet Connect</span>
                </div>
                <div className="right-arrow">
                  <img src="/assets/img/icon/arrow.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
