import { useWeb3React } from "@web3-react/core";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { injected, shortenAddress, walletconnect } from "../utils";
import useToast from "../useToast";
import useAuth from "../userAuth";

export default function Header() {
  const { active, account } = useWeb3React();
  const navigate = useNavigate();
  const { toastError } = useToast();
  const { login } = useAuth()

  const authenticate = async (connector) => {
    await login(connector)
    document.getElementById('connect_wallet_close').click();
  }

  return (
    <>
      <div className="header position-relative">
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              <div className=" navbar-collapse d-lg-flex align-items-center  justify-content-lg-between">
                <div className="nav_flex">
                  <Link to={"/"} className="navbar-brand">
                    <img src={"/assets/img/logo/logo.svg"} alt="logo" />
                  </Link>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon" />
                    <span className="navbar-toggler-icon" />
                    <span className="navbar-toggler-icon" />
                  </button>
                </div>
                <div className="">
                  <ul
                    className="navbar-nav me-auto mb-2 mb-lg-0 align-items-center mt-lg-0 collapse"
                    id="navbarSupportedContent"
                  >
                    {/* <li className="nav-item" style={{ cursor: 'pointer' }}>
                      <a onClick={() => navigate('/citylist')} className="nav-link nav-link_header">Melonverse Cities</a>
                    </li> */}
                    <li className="nav-item" style={{ cursor: "pointer" }}>
                      <a
                        onClick={() => navigate("/map")}
                        className="nav-link nav-link_header"
                        aria-current="page"
                      >
                        Map
                      </a>
                    </li>
                    {/* <li className="nav-item" style={{ cursor: 'pointer' }}>
                      <a href="#" className="nav-link nav-link_header" >My Account</a>
                    </li> */}
                    {/* <li className="nav-item">
                      <Link to={""} className="nav-link nav-link_header" tabIndex={-1}>Profile</Link>
                    </li> */}
                    <li className="nav-item" style={{ cursor: "pointer" }}>
                      <a
                        onClick={() => {
                          if (!active) {
                            toastError("Please Connect Your Wallet");
                          } else {
                            navigate("/parcellist");
                          }
                        }}
                        className="nav-link nav-link_header"
                      >
                        My Parcels
                      </a>
                    </li>

                    <li>
                      <div className="wallet_connect ms-lg-4 mt-md-2 mt-lg-0">
                        <button
                          className="wallet_btn"
                          data-bs-toggle="modal"
                          data-bs-target={account ? '' : "#connect_wallet_modal"}
                        >
                          {active ? shortenAddress(account) : "Wallet Connect"}
                        </button>
                        {account == '0x3e0595C102E894DAC9b7b85C952A819da86B82AD' && <button className="wallet_btn ms-2" onClick={() => {  navigate('/admin/login') }}>Login</button>}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="connect_wallet_modal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="connect_wallet_modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered login_model">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_wallet_modalLabel">
                Wallet Connect
              </h5>
              <button
                id='connect_wallet_close'
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="align-items-center d-flex justify-content-between wallet_box" onClick={() => authenticate(injected)}>
                <div className="align-items-center d-flex wallet">
                  <img src="/assets/img/icon/meta.svg" />
                  <span>Metamask</span>
                </div>
                <div className="right-arrow">
                  <img src="/assets/img/icon/arrow.svg" />
                </div>
              </div>
              {/* <div className="align-items-center d-flex justify-content-between wallet_box" onClick={() => authenticate(walletconnect)}>
                <div className="align-items-center d-flex wallet">
                  <img src="/assets/img/icon/wallet.svg" />
                  <span>Wallet Connect</span>
                </div>
                <div className="right-arrow">
                  <img src="/assets/img/icon/arrow.svg" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="header">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="mainlogo">
                            <Link to={"/"}><img src="/assets/img/logo/logo.svg" alt="logo" /></Link>
                        </div>
                        <div className="mainlogo">
                            <Link to={"/parcellist"}>Your Parcels</Link>
                        </div>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="#">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Link</a>
                                </li>
                            </ul></div>
                        <div className="wallet_connect">
                            <button onClick={() => onWalletConnectClick()} className="wallet_btn">{active ? shortenAddress(account) : 'Wallet Connect'}
                                <svg className="ms-2" xmlns="http://www.w3.org/2000/svg" width="6.755" height="10.681" viewBox="0 0 6.755 10.681">
                                    <path id="Path_2712" data-name="Path 2712" d="M547.032,1016.226l4.633,4.633-4.633,4.633" transform="translate(-546.325 -1015.519)" fill="none" stroke="#fff" stroke-width="2" />
                                </svg>
                            </button>
                        </div>

                    </div>
                </div>
            </div> */}
    </>
  );
}
