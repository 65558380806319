export const ROOT_URL = 'https://backend.celebverse.live';
// export const ROOT_URL = 'http://192.168.0.157:1111';

const APPROVE_CONTRACT_ADDRESS = '';
const APPROVE_CONTRACT_ABI = [];

// TEST NET

// const RPC_URL = 'https://data-seed-prebsc-1-s1.binance.org:8545/';
// const BLOCK_EXPLORE_URL = 'https://testnet.bscscan.com/';

// const CONTRACT_ADDRESS = '0xD8E26D869b17A79D4891B6D7263C0a542c110245';
// const ABI = [{
// 	"inputs": [{
// 		"internalType": "string[]",
// 		"name": "_city",
// 		"type": "string[]"
// 	}, {
// 		"internalType": "uint256[]",
// 		"name": "_Totalblock",
// 		"type": "uint256[]"
// 	}, {
// 		"internalType": "address",
// 		"name": "_admin",
// 		"type": "address"
// 	}],
// 	"stateMutability": "nonpayable",
// 	"type": "constructor"
// }, {
// 	"anonymous": false,
// 	"inputs": [{
// 		"indexed": true,
// 		"internalType": "address",
// 		"name": "owner",
// 		"type": "address"
// 	}, {
// 		"indexed": true,
// 		"internalType": "address",
// 		"name": "approved",
// 		"type": "address"
// 	}, {
// 		"indexed": true,
// 		"internalType": "uint256",
// 		"name": "tokenId",
// 		"type": "uint256"
// 	}],
// 	"name": "Approval",
// 	"type": "event"
// }, {
// 	"anonymous": false,
// 	"inputs": [{
// 		"indexed": true,
// 		"internalType": "address",
// 		"name": "owner",
// 		"type": "address"
// 	}, {
// 		"indexed": true,
// 		"internalType": "address",
// 		"name": "operator",
// 		"type": "address"
// 	}, {
// 		"indexed": false,
// 		"internalType": "bool",
// 		"name": "approved",
// 		"type": "bool"
// 	}],
// 	"name": "ApprovalForAll",
// 	"type": "event"
// }, {
// 	"anonymous": false,
// 	"inputs": [{
// 		"indexed": true,
// 		"internalType": "address",
// 		"name": "previousOwner",
// 		"type": "address"
// 	}, {
// 		"indexed": true,
// 		"internalType": "address",
// 		"name": "newOwner",
// 		"type": "address"
// 	}],
// 	"name": "OwnershipTransferred",
// 	"type": "event"
// }, {
// 	"anonymous": false,
// 	"inputs": [{
// 		"indexed": false,
// 		"internalType": "address",
// 		"name": "registeraddress",
// 		"type": "address"
// 	}, {
// 		"indexed": false,
// 		"internalType": "uint256",
// 		"name": "registertime",
// 		"type": "uint256"
// 	}],
// 	"name": "Register",
// 	"type": "event"
// }, {
// 	"anonymous": false,
// 	"inputs": [{
// 		"indexed": true,
// 		"internalType": "address",
// 		"name": "from",
// 		"type": "address"
// 	}, {
// 		"indexed": true,
// 		"internalType": "address",
// 		"name": "to",
// 		"type": "address"
// 	}, {
// 		"indexed": true,
// 		"internalType": "uint256",
// 		"name": "tokenId",
// 		"type": "uint256"
// 	}],
// 	"name": "Transfer",
// 	"type": "event"
// }, {
// 	"anonymous": false,
// 	"inputs": [{
// 		"indexed": false,
// 		"internalType": "uint256",
// 		"name": "tokenId",
// 		"type": "uint256"
// 	}, {
// 		"indexed": false,
// 		"internalType": "uint256",
// 		"name": "auctionId",
// 		"type": "uint256"
// 	}],
// 	"name": "closed",
// 	"type": "event"
// }, {
// 	"anonymous": false,
// 	"inputs": [{
// 		"indexed": false,
// 		"internalType": "address",
// 		"name": "_address",
// 		"type": "address"
// 	}],
// 	"name": "main",
// 	"type": "event"
// }, {
// 	"anonymous": false,
// 	"inputs": [{
// 		"indexed": false,
// 		"internalType": "address",
// 		"name": "_address",
// 		"type": "address"
// 	}, {
// 		"indexed": false,
// 		"internalType": "uint256",
// 		"name": "_Amount",
// 		"type": "uint256"
// 	}],
// 	"name": "mainevent",
// 	"type": "event"
// }, {
// 	"anonymous": false,
// 	"inputs": [{
// 		"indexed": false,
// 		"internalType": "address",
// 		"name": "user",
// 		"type": "address"
// 	}, {
// 		"indexed": false,
// 		"internalType": "uint256",
// 		"name": "totalblock",
// 		"type": "uint256"
// 	}, {
// 		"indexed": false,
// 		"internalType": "uint256",
// 		"name": "totalUSDamount",
// 		"type": "uint256"
// 	}, {
// 		"indexed": false,
// 		"internalType": "uint256",
// 		"name": "totalBNBamount",
// 		"type": "uint256"
// 	}, {
// 		"indexed": false,
// 		"internalType": "bool",
// 		"name": "isregister",
// 		"type": "bool"
// 	}],
// 	"name": "mainlog",
// 	"type": "event"
// }, {
// 	"anonymous": false,
// 	"inputs": [{
// 		"indexed": false,
// 		"internalType": "uint256",
// 		"name": "collections",
// 		"type": "uint256"
// 	}, {
// 		"indexed": false,
// 		"internalType": "uint256",
// 		"name": "totalIDs",
// 		"type": "uint256"
// 	}, {
// 		"indexed": false,
// 		"internalType": "string",
// 		"name": "URI",
// 		"type": "string"
// 	}, {
// 		"indexed": false,
// 		"internalType": "uint256",
// 		"name": "royalty",
// 		"type": "uint256"
// 	}],
// 	"name": "onCollectionMint",
// 	"type": "event"
// }, {
// 	"anonymous": false,
// 	"inputs": [{
// 		"indexed": false,
// 		"internalType": "uint256",
// 		"name": "tokenid",
// 		"type": "uint256"
// 	}, {
// 		"indexed": false,
// 		"internalType": "uint256",
// 		"name": "adminCommision",
// 		"type": "uint256"
// 	}, {
// 		"indexed": false,
// 		"internalType": "uint256",
// 		"name": "creatorRoyalty",
// 		"type": "uint256"
// 	}, {
// 		"indexed": false,
// 		"internalType": "uint256",
// 		"name": "ownerAmount",
// 		"type": "uint256"
// 	}],
// 	"name": "onCommision",
// 	"type": "event"
// }, {
// 	"anonymous": false,
// 	"inputs": [{
// 		"indexed": false,
// 		"internalType": "uint256",
// 		"name": "TokenId",
// 		"type": "uint256"
// 	}, {
// 		"indexed": false,
// 		"internalType": "int256",
// 		"name": "xaxis",
// 		"type": "int256"
// 	}, {
// 		"indexed": false,
// 		"internalType": "int256",
// 		"name": "yaxis",
// 		"type": "int256"
// 	}, {
// 		"indexed": false,
// 		"internalType": "address",
// 		"name": "creator",
// 		"type": "address"
// 	}, {
// 		"indexed": false,
// 		"internalType": "uint256",
// 		"name": "USDT",
// 		"type": "uint256"
// 	}, {
// 		"indexed": false,
// 		"internalType": "uint256",
// 		"name": "BNB",
// 		"type": "uint256"
// 	}],
// 	"name": "onMint",
// 	"type": "event"
// }, {
// 	"anonymous": false,
// 	"inputs": [{
// 		"indexed": false,
// 		"internalType": "uint256",
// 		"name": "tokenId",
// 		"type": "uint256"
// 	}, {
// 		"indexed": false,
// 		"internalType": "address",
// 		"name": "beneficiary",
// 		"type": "address"
// 	}, {
// 		"indexed": false,
// 		"internalType": "uint256",
// 		"name": "startTime",
// 		"type": "uint256"
// 	}, {
// 		"indexed": false,
// 		"internalType": "uint256",
// 		"name": "endTime",
// 		"type": "uint256"
// 	}, {
// 		"indexed": false,
// 		"internalType": "uint256",
// 		"name": "reservePrice",
// 		"type": "uint256"
// 	}, {
// 		"indexed": false,
// 		"internalType": "bool",
// 		"name": "isNative",
// 		"type": "bool"
// 	}],
// 	"name": "sell_auction_create",
// 	"type": "event"
// }, {
// 	"anonymous": false,
// 	"inputs": [{
// 		"indexed": false,
// 		"internalType": "uint256",
// 		"name": "letestprice",
// 		"type": "uint256"
// 	}, {
// 		"indexed": false,
// 		"internalType": "uint256",
// 		"name": "buyprice",
// 		"type": "uint256"
// 	}],
// 	"name": "upd",
// 	"type": "event"
// }, {
// 	"inputs": [{
// 		"internalType": "string[]",
// 		"name": "_codelist",
// 		"type": "string[]"
// 	}],
// 	"name": "Addcode",
// 	"outputs": [{
// 		"internalType": "bool",
// 		"name": "",
// 		"type": "bool"
// 	}],
// 	"stateMutability": "nonpayable",
// 	"type": "function"
// }, {
// 	"inputs": [],
// 	"name": "Admin",
// 	"outputs": [{
// 		"internalType": "address",
// 		"name": "",
// 		"type": "address"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "int256[]",
// 		"name": "x",
// 		"type": "int256[]"
// 	}, {
// 		"internalType": "int256[]",
// 		"name": "y",
// 		"type": "int256[]"
// 	}, {
// 		"internalType": "address[]",
// 		"name": "_a",
// 		"type": "address[]"
// 	}, {
// 		"internalType": "uint256[]",
// 		"name": "_price",
// 		"type": "uint256[]"
// 	}, {
// 		"internalType": "string",
// 		"name": "_cityname",
// 		"type": "string"
// 	}],
// 	"name": "AssignToken",
// 	"outputs": [{
// 		"internalType": "bool",
// 		"name": "",
// 		"type": "bool"
// 	}],
// 	"stateMutability": "nonpayable",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "int256",
// 		"name": "_x",
// 		"type": "int256"
// 	}, {
// 		"internalType": "int256",
// 		"name": "_y",
// 		"type": "int256"
// 	}],
// 	"name": "CheckOwner",
// 	"outputs": [{
// 		"internalType": "address",
// 		"name": "",
// 		"type": "address"
// 	}, {
// 		"internalType": "string",
// 		"name": "",
// 		"type": "string"
// 	}, {
// 		"components": [{
// 			"internalType": "int256",
// 			"name": "xaxis",
// 			"type": "int256"
// 		}, {
// 			"internalType": "int256",
// 			"name": "yaxis",
// 			"type": "int256"
// 		}, {
// 			"internalType": "string",
// 			"name": "cityname",
// 			"type": "string"
// 		}],
// 		"internalType": "struct Vision.ParselData",
// 		"name": "",
// 		"type": "tuple"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "string",
// 		"name": "_cname",
// 		"type": "string"
// 	}],
// 	"name": "GetData",
// 	"outputs": [{
// 		"internalType": "uint256[]",
// 		"name": "",
// 		"type": "uint256[]"
// 	}, {
// 		"internalType": "uint256[]",
// 		"name": "",
// 		"type": "uint256[]"
// 	}, {
// 		"internalType": "uint256",
// 		"name": "",
// 		"type": "uint256"
// 	}, {
// 		"internalType": "uint256",
// 		"name": "",
// 		"type": "uint256"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "address",
// 		"name": "_contract",
// 		"type": "address"
// 	}, {
// 		"internalType": "address",
// 		"name": "user",
// 		"type": "address"
// 	}],
// 	"name": "Givemetoken",
// 	"outputs": [{
// 		"internalType": "bool",
// 		"name": "",
// 		"type": "bool"
// 	}],
// 	"stateMutability": "nonpayable",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "address",
// 		"name": "_a",
// 		"type": "address"
// 	}, {
// 		"internalType": "uint256",
// 		"name": "_v",
// 		"type": "uint256"
// 	}],
// 	"name": "Givemetoken",
// 	"outputs": [{
// 		"internalType": "bool",
// 		"name": "",
// 		"type": "bool"
// 	}],
// 	"stateMutability": "nonpayable",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "bool",
// 		"name": "_b",
// 		"type": "bool"
// 	}],
// 	"name": "RemoveLock",
// 	"outputs": [{
// 		"internalType": "bool",
// 		"name": "",
// 		"type": "bool"
// 	}],
// 	"stateMutability": "nonpayable",
// 	"type": "function"
// }, {
// 	"inputs": [],
// 	"name": "allunlock",
// 	"outputs": [{
// 		"internalType": "bool",
// 		"name": "",
// 		"type": "bool"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "address",
// 		"name": "to",
// 		"type": "address"
// 	}, {
// 		"internalType": "uint256",
// 		"name": "tokenId",
// 		"type": "uint256"
// 	}],
// 	"name": "approve",
// 	"outputs": [],
// 	"stateMutability": "nonpayable",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "int256",
// 		"name": "",
// 		"type": "int256"
// 	}, {
// 		"internalType": "int256",
// 		"name": "",
// 		"type": "int256"
// 	}],
// 	"name": "axis",
// 	"outputs": [{
// 		"internalType": "bool",
// 		"name": "",
// 		"type": "bool"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "address",
// 		"name": "owner",
// 		"type": "address"
// 	}],
// 	"name": "balanceOf",
// 	"outputs": [{
// 		"internalType": "uint256",
// 		"name": "",
// 		"type": "uint256"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "address",
// 		"name": "_admin",
// 		"type": "address"
// 	}],
// 	"name": "changeAdmin",
// 	"outputs": [{
// 		"internalType": "bool",
// 		"name": "",
// 		"type": "bool"
// 	}],
// 	"stateMutability": "nonpayable",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "uint256",
// 		"name": "_days1",
// 		"type": "uint256"
// 	}, {
// 		"internalType": "uint256",
// 		"name": "_days5",
// 		"type": "uint256"
// 	}, {
// 		"internalType": "uint256",
// 		"name": "_days2",
// 		"type": "uint256"
// 	}],
// 	"name": "changeLockDays",
// 	"outputs": [{
// 		"internalType": "bool",
// 		"name": "",
// 		"type": "bool"
// 	}],
// 	"stateMutability": "nonpayable",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "uint256",
// 		"name": "_amount",
// 		"type": "uint256"
// 	}],
// 	"name": "changePlateFormFee",
// 	"outputs": [{
// 		"internalType": "bool",
// 		"name": "",
// 		"type": "bool"
// 	}],
// 	"stateMutability": "nonpayable",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "uint256[]",
// 		"name": "_tokenid",
// 		"type": "uint256[]"
// 	}, {
// 		"internalType": "uint256",
// 		"name": "_time",
// 		"type": "uint256"
// 	}],
// 	"name": "changeid",
// 	"outputs": [{
// 		"internalType": "bool",
// 		"name": "",
// 		"type": "bool"
// 	}],
// 	"stateMutability": "nonpayable",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "address",
// 		"name": "_address",
// 		"type": "address"
// 	}],
// 	"name": "changeplateformFee",
// 	"outputs": [{
// 		"internalType": "bool",
// 		"name": "",
// 		"type": "bool"
// 	}],
// 	"stateMutability": "nonpayable",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "address",
// 		"name": "_usdt",
// 		"type": "address"
// 	}],
// 	"name": "changetokenaddress",
// 	"outputs": [{
// 		"internalType": "bool",
// 		"name": "",
// 		"type": "bool"
// 	}],
// 	"stateMutability": "nonpayable",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "string",
// 		"name": "",
// 		"type": "string"
// 	}],
// 	"name": "city",
// 	"outputs": [{
// 		"internalType": "bool",
// 		"name": "",
// 		"type": "bool"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "string",
// 		"name": "",
// 		"type": "string"
// 	}],
// 	"name": "code_",
// 	"outputs": [{
// 		"internalType": "bool",
// 		"name": "",
// 		"type": "bool"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "uint256",
// 		"name": "_tokenId",
// 		"type": "uint256"
// 	}],
// 	"name": "creatorOf",
// 	"outputs": [{
// 		"internalType": "address",
// 		"name": "",
// 		"type": "address"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "uint256",
// 		"name": "tokenId",
// 		"type": "uint256"
// 	}],
// 	"name": "getApproved",
// 	"outputs": [{
// 		"internalType": "address",
// 		"name": "",
// 		"type": "address"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "address",
// 		"name": "_contractaddress",
// 		"type": "address"
// 	}, {
// 		"internalType": "uint256",
// 		"name": "_amount",
// 		"type": "uint256"
// 	}, {
// 		"internalType": "uint256",
// 		"name": "_time",
// 		"type": "uint256"
// 	}, {
// 		"internalType": "int256",
// 		"name": "x",
// 		"type": "int256"
// 	}, {
// 		"internalType": "int256",
// 		"name": "y",
// 		"type": "int256"
// 	}, {
// 		"internalType": "string",
// 		"name": "code",
// 		"type": "string"
// 	}],
// 	"name": "getMessageHash",
// 	"outputs": [{
// 		"internalType": "bytes32",
// 		"name": "",
// 		"type": "bytes32"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "address",
// 		"name": "owner",
// 		"type": "address"
// 	}, {
// 		"internalType": "address",
// 		"name": "operator",
// 		"type": "address"
// 	}],
// 	"name": "isApprovedForAll",
// 	"outputs": [{
// 		"internalType": "bool",
// 		"name": "",
// 		"type": "bool"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "string",
// 		"name": "",
// 		"type": "string"
// 	}],
// 	"name": "iscodeuse",
// 	"outputs": [{
// 		"internalType": "bool",
// 		"name": "",
// 		"type": "bool"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "uint256",
// 		"name": "",
// 		"type": "uint256"
// 	}],
// 	"name": "isnative",
// 	"outputs": [{
// 		"internalType": "bool",
// 		"name": "",
// 		"type": "bool"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [],
// 	"name": "lasttime",
// 	"outputs": [{
// 		"internalType": "uint256",
// 		"name": "",
// 		"type": "uint256"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [],
// 	"name": "lockdays1",
// 	"outputs": [{
// 		"internalType": "uint256",
// 		"name": "",
// 		"type": "uint256"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [],
// 	"name": "lockdays2",
// 	"outputs": [{
// 		"internalType": "uint256",
// 		"name": "",
// 		"type": "uint256"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [],
// 	"name": "lockdays5",
// 	"outputs": [{
// 		"internalType": "uint256",
// 		"name": "",
// 		"type": "uint256"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "string",
// 		"name": "",
// 		"type": "string"
// 	}, {
// 		"internalType": "uint256",
// 		"name": "",
// 		"type": "uint256"
// 	}],
// 	"name": "mintedamountblock",
// 	"outputs": [{
// 		"internalType": "uint256",
// 		"name": "",
// 		"type": "uint256"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "string",
// 		"name": "",
// 		"type": "string"
// 	}],
// 	"name": "mintedblock",
// 	"outputs": [{
// 		"internalType": "uint256",
// 		"name": "",
// 		"type": "uint256"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "uint256",
// 		"name": "_amount",
// 		"type": "uint256"
// 	}, {
// 		"internalType": "uint256",
// 		"name": "_time",
// 		"type": "uint256"
// 	}, {
// 		"internalType": "string",
// 		"name": "code",
// 		"type": "string"
// 	}, {
// 		"internalType": "bytes",
// 		"name": "signature",
// 		"type": "bytes"
// 	}, {
// 		"internalType": "int256",
// 		"name": "_x",
// 		"type": "int256"
// 	}, {
// 		"internalType": "int256",
// 		"name": "_y",
// 		"type": "int256"
// 	}, {
// 		"internalType": "string",
// 		"name": "_cityname",
// 		"type": "string"
// 	}, {
// 		"internalType": "uint256",
// 		"name": "_usd",
// 		"type": "uint256"
// 	}],
// 	"name": "multibuyparsel",
// 	"outputs": [{
// 		"internalType": "bool",
// 		"name": "",
// 		"type": "bool"
// 	}],
// 	"stateMutability": "payable",
// 	"type": "function"
// }, {
// 	"inputs": [],
// 	"name": "name",
// 	"outputs": [{
// 		"internalType": "string",
// 		"name": "",
// 		"type": "string"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "uint256",
// 		"name": "_tokenId",
// 		"type": "uint256"
// 	}],
// 	"name": "nativeBuy",
// 	"outputs": [{
// 		"internalType": "bool",
// 		"name": "",
// 		"type": "bool"
// 	}],
// 	"stateMutability": "payable",
// 	"type": "function"
// }, {
// 	"inputs": [],
// 	"name": "nativecommision",
// 	"outputs": [{
// 		"internalType": "uint256",
// 		"name": "",
// 		"type": "uint256"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "string[]",
// 		"name": "_nc",
// 		"type": "string[]"
// 	}, {
// 		"internalType": "uint256[]",
// 		"name": "_totalBlock",
// 		"type": "uint256[]"
// 	}],
// 	"name": "newcity",
// 	"outputs": [{
// 		"internalType": "bool",
// 		"name": "",
// 		"type": "bool"
// 	}],
// 	"stateMutability": "nonpayable",
// 	"type": "function"
// }, {
// 	"inputs": [],
// 	"name": "owner",
// 	"outputs": [{
// 		"internalType": "address",
// 		"name": "",
// 		"type": "address"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "uint256",
// 		"name": "tokenId",
// 		"type": "uint256"
// 	}],
// 	"name": "ownerOf",
// 	"outputs": [{
// 		"internalType": "address",
// 		"name": "",
// 		"type": "address"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "uint256",
// 		"name": "",
// 		"type": "uint256"
// 	}],
// 	"name": "parseldata",
// 	"outputs": [{
// 		"internalType": "int256",
// 		"name": "xaxis",
// 		"type": "int256"
// 	}, {
// 		"internalType": "int256",
// 		"name": "yaxis",
// 		"type": "int256"
// 	}, {
// 		"internalType": "string",
// 		"name": "cityname",
// 		"type": "string"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [],
// 	"name": "plateformFee",
// 	"outputs": [{
// 		"internalType": "address",
// 		"name": "",
// 		"type": "address"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "uint256",
// 		"name": "_tokenId",
// 		"type": "uint256"
// 	}],
// 	"name": "removeSell",
// 	"outputs": [{
// 		"internalType": "bool",
// 		"name": "",
// 		"type": "bool"
// 	}],
// 	"stateMutability": "nonpayable",
// 	"type": "function"
// }, {
// 	"inputs": [],
// 	"name": "renounceOwnership",
// 	"outputs": [],
// 	"stateMutability": "nonpayable",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "address",
// 		"name": "from",
// 		"type": "address"
// 	}, {
// 		"internalType": "address",
// 		"name": "to",
// 		"type": "address"
// 	}, {
// 		"internalType": "uint256",
// 		"name": "tokenId",
// 		"type": "uint256"
// 	}],
// 	"name": "safeTransferFrom",
// 	"outputs": [],
// 	"stateMutability": "nonpayable",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "address",
// 		"name": "from",
// 		"type": "address"
// 	}, {
// 		"internalType": "address",
// 		"name": "to",
// 		"type": "address"
// 	}, {
// 		"internalType": "uint256",
// 		"name": "tokenId",
// 		"type": "uint256"
// 	}, {
// 		"internalType": "bytes",
// 		"name": "data",
// 		"type": "bytes"
// 	}],
// 	"name": "safeTransferFrom",
// 	"outputs": [],
// 	"stateMutability": "nonpayable",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "uint256[]",
// 		"name": "_tokenId",
// 		"type": "uint256[]"
// 	}, {
// 		"internalType": "uint256[]",
// 		"name": "_price",
// 		"type": "uint256[]"
// 	}, {
// 		"internalType": "address",
// 		"name": "_contract",
// 		"type": "address"
// 	}],
// 	"name": "sell",
// 	"outputs": [{
// 		"internalType": "bool",
// 		"name": "",
// 		"type": "bool"
// 	}],
// 	"stateMutability": "nonpayable",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "uint256",
// 		"name": "",
// 		"type": "uint256"
// 	}],
// 	"name": "sellDetails",
// 	"outputs": [{
// 		"internalType": "address",
// 		"name": "seller",
// 		"type": "address"
// 	}, {
// 		"internalType": "address",
// 		"name": "buyer",
// 		"type": "address"
// 	}, {
// 		"internalType": "uint256",
// 		"name": "price",
// 		"type": "uint256"
// 	}, {
// 		"internalType": "bool",
// 		"name": "isnative",
// 		"type": "bool"
// 	}, {
// 		"internalType": "bool",
// 		"name": "open",
// 		"type": "bool"
// 	}, {
// 		"internalType": "address",
// 		"name": "tcontract",
// 		"type": "address"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "address",
// 		"name": "operator",
// 		"type": "address"
// 	}, {
// 		"internalType": "bool",
// 		"name": "approved",
// 		"type": "bool"
// 	}],
// 	"name": "setApprovalForAll",
// 	"outputs": [],
// 	"stateMutability": "nonpayable",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "bytes4",
// 		"name": "interfaceId",
// 		"type": "bytes4"
// 	}],
// 	"name": "supportsInterface",
// 	"outputs": [{
// 		"internalType": "bool",
// 		"name": "",
// 		"type": "bool"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [],
// 	"name": "symbol",
// 	"outputs": [{
// 		"internalType": "string",
// 		"name": "",
// 		"type": "string"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "uint256",
// 		"name": "tokenId",
// 		"type": "uint256"
// 	}],
// 	"name": "tokenURI",
// 	"outputs": [{
// 		"internalType": "string",
// 		"name": "",
// 		"type": "string"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "uint256",
// 		"name": "",
// 		"type": "uint256"
// 	}],
// 	"name": "tokenlock",
// 	"outputs": [{
// 		"internalType": "uint256",
// 		"name": "",
// 		"type": "uint256"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [],
// 	"name": "totalSupply",
// 	"outputs": [{
// 		"internalType": "uint256",
// 		"name": "",
// 		"type": "uint256"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "string",
// 		"name": "",
// 		"type": "string"
// 	}],
// 	"name": "totalblock",
// 	"outputs": [{
// 		"internalType": "uint256",
// 		"name": "",
// 		"type": "uint256"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "address",
// 		"name": "from",
// 		"type": "address"
// 	}, {
// 		"internalType": "address",
// 		"name": "to",
// 		"type": "address"
// 	}, {
// 		"internalType": "uint256",
// 		"name": "tokenId",
// 		"type": "uint256"
// 	}],
// 	"name": "transferFrom",
// 	"outputs": [],
// 	"stateMutability": "nonpayable",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "address",
// 		"name": "newOwner",
// 		"type": "address"
// 	}],
// 	"name": "transferOwnership",
// 	"outputs": [],
// 	"stateMutability": "nonpayable",
// 	"type": "function"
// }, {
// 	"inputs": [],
// 	"name": "usdt",
// 	"outputs": [{
// 		"internalType": "address",
// 		"name": "",
// 		"type": "address"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"inputs": [{
// 		"internalType": "address",
// 		"name": "_signer",
// 		"type": "address"
// 	}, {
// 		"internalType": "address",
// 		"name": "_contractaddress",
// 		"type": "address"
// 	}, {
// 		"internalType": "uint256",
// 		"name": "_amount",
// 		"type": "uint256"
// 	}, {
// 		"internalType": "uint256",
// 		"name": "_time",
// 		"type": "uint256"
// 	}, {
// 		"internalType": "string",
// 		"name": "code",
// 		"type": "string"
// 	}, {
// 		"internalType": "int256",
// 		"name": "x",
// 		"type": "int256"
// 	}, {
// 		"internalType": "int256",
// 		"name": "y",
// 		"type": "int256"
// 	}, {
// 		"internalType": "bytes",
// 		"name": "signature",
// 		"type": "bytes"
// 	}],
// 	"name": "verify",
// 	"outputs": [{
// 		"internalType": "bool",
// 		"name": "",
// 		"type": "bool"
// 	}],
// 	"stateMutability": "view",
// 	"type": "function"
// }, {
// 	"stateMutability": "payable",
// 	"type": "receive"
// }];

// MAIN NET

const RPC_URL = 'https://bsc-dataseed.binance.org/';
const BLOCK_EXPLORE_URL = 'https://bscscan.com';

const CONTRACT_ADDRESS = '0x2889D5285b9aEaA13c7E97BFCF63a04a5dc2aa5B';
const ABI = [{
	"inputs": [{
		"internalType": "string[]",
		"name": "_city",
		"type": "string[]"
	}, {
		"internalType": "uint256[]",
		"name": "_Totalblock",
		"type": "uint256[]"
	}, {
		"internalType": "address",
		"name": "_admin",
		"type": "address"
	}],
	"stateMutability": "nonpayable",
	"type": "constructor"
}, {
	"anonymous": false,
	"inputs": [{
		"indexed": true,
		"internalType": "address",
		"name": "owner",
		"type": "address"
	}, {
		"indexed": true,
		"internalType": "address",
		"name": "approved",
		"type": "address"
	}, {
		"indexed": true,
		"internalType": "uint256",
		"name": "tokenId",
		"type": "uint256"
	}],
	"name": "Approval",
	"type": "event"
}, {
	"anonymous": false,
	"inputs": [{
		"indexed": true,
		"internalType": "address",
		"name": "owner",
		"type": "address"
	}, {
		"indexed": true,
		"internalType": "address",
		"name": "operator",
		"type": "address"
	}, {
		"indexed": false,
		"internalType": "bool",
		"name": "approved",
		"type": "bool"
	}],
	"name": "ApprovalForAll",
	"type": "event"
}, {
	"anonymous": false,
	"inputs": [{
		"indexed": true,
		"internalType": "address",
		"name": "previousOwner",
		"type": "address"
	}, {
		"indexed": true,
		"internalType": "address",
		"name": "newOwner",
		"type": "address"
	}],
	"name": "OwnershipTransferred",
	"type": "event"
}, {
	"anonymous": false,
	"inputs": [{
		"indexed": false,
		"internalType": "address",
		"name": "registeraddress",
		"type": "address"
	}, {
		"indexed": false,
		"internalType": "uint256",
		"name": "registertime",
		"type": "uint256"
	}],
	"name": "Register",
	"type": "event"
}, {
	"anonymous": false,
	"inputs": [{
		"indexed": true,
		"internalType": "address",
		"name": "from",
		"type": "address"
	}, {
		"indexed": true,
		"internalType": "address",
		"name": "to",
		"type": "address"
	}, {
		"indexed": true,
		"internalType": "uint256",
		"name": "tokenId",
		"type": "uint256"
	}],
	"name": "Transfer",
	"type": "event"
}, {
	"anonymous": false,
	"inputs": [{
		"indexed": false,
		"internalType": "uint256",
		"name": "tokenId",
		"type": "uint256"
	}, {
		"indexed": false,
		"internalType": "uint256",
		"name": "auctionId",
		"type": "uint256"
	}],
	"name": "closed",
	"type": "event"
}, {
	"anonymous": false,
	"inputs": [{
		"indexed": false,
		"internalType": "address",
		"name": "_address",
		"type": "address"
	}],
	"name": "main",
	"type": "event"
}, {
	"anonymous": false,
	"inputs": [{
		"indexed": false,
		"internalType": "address",
		"name": "_address",
		"type": "address"
	}, {
		"indexed": false,
		"internalType": "uint256",
		"name": "_Amount",
		"type": "uint256"
	}],
	"name": "mainevent",
	"type": "event"
}, {
	"anonymous": false,
	"inputs": [{
		"indexed": false,
		"internalType": "address",
		"name": "user",
		"type": "address"
	}, {
		"indexed": false,
		"internalType": "uint256",
		"name": "totalblock",
		"type": "uint256"
	}, {
		"indexed": false,
		"internalType": "uint256",
		"name": "totalUSDamount",
		"type": "uint256"
	}, {
		"indexed": false,
		"internalType": "uint256",
		"name": "totalBNBamount",
		"type": "uint256"
	}, {
		"indexed": false,
		"internalType": "bool",
		"name": "isregister",
		"type": "bool"
	}],
	"name": "mainlog",
	"type": "event"
}, {
	"anonymous": false,
	"inputs": [{
		"indexed": false,
		"internalType": "uint256",
		"name": "collections",
		"type": "uint256"
	}, {
		"indexed": false,
		"internalType": "uint256",
		"name": "totalIDs",
		"type": "uint256"
	}, {
		"indexed": false,
		"internalType": "string",
		"name": "URI",
		"type": "string"
	}, {
		"indexed": false,
		"internalType": "uint256",
		"name": "royalty",
		"type": "uint256"
	}],
	"name": "onCollectionMint",
	"type": "event"
}, {
	"anonymous": false,
	"inputs": [{
		"indexed": false,
		"internalType": "uint256",
		"name": "tokenid",
		"type": "uint256"
	}, {
		"indexed": false,
		"internalType": "uint256",
		"name": "adminCommision",
		"type": "uint256"
	}, {
		"indexed": false,
		"internalType": "uint256",
		"name": "creatorRoyalty",
		"type": "uint256"
	}, {
		"indexed": false,
		"internalType": "uint256",
		"name": "ownerAmount",
		"type": "uint256"
	}],
	"name": "onCommision",
	"type": "event"
}, {
	"anonymous": false,
	"inputs": [{
		"indexed": false,
		"internalType": "uint256",
		"name": "TokenId",
		"type": "uint256"
	}, {
		"indexed": false,
		"internalType": "int256",
		"name": "xaxis",
		"type": "int256"
	}, {
		"indexed": false,
		"internalType": "int256",
		"name": "yaxis",
		"type": "int256"
	}, {
		"indexed": false,
		"internalType": "address",
		"name": "creator",
		"type": "address"
	}, {
		"indexed": false,
		"internalType": "uint256",
		"name": "USDT",
		"type": "uint256"
	}, {
		"indexed": false,
		"internalType": "uint256",
		"name": "BNB",
		"type": "uint256"
	}],
	"name": "onMint",
	"type": "event"
}, {
	"anonymous": false,
	"inputs": [{
		"indexed": false,
		"internalType": "uint256",
		"name": "tokenId",
		"type": "uint256"
	}, {
		"indexed": false,
		"internalType": "address",
		"name": "beneficiary",
		"type": "address"
	}, {
		"indexed": false,
		"internalType": "uint256",
		"name": "startTime",
		"type": "uint256"
	}, {
		"indexed": false,
		"internalType": "uint256",
		"name": "endTime",
		"type": "uint256"
	}, {
		"indexed": false,
		"internalType": "uint256",
		"name": "reservePrice",
		"type": "uint256"
	}, {
		"indexed": false,
		"internalType": "bool",
		"name": "isNative",
		"type": "bool"
	}],
	"name": "sell_auction_create",
	"type": "event"
}, {
	"anonymous": false,
	"inputs": [{
		"indexed": false,
		"internalType": "uint256",
		"name": "letestprice",
		"type": "uint256"
	}, {
		"indexed": false,
		"internalType": "uint256",
		"name": "buyprice",
		"type": "uint256"
	}],
	"name": "upd",
	"type": "event"
}, {
	"inputs": [{
		"internalType": "string[]",
		"name": "_codelist",
		"type": "string[]"
	}],
	"name": "Addcode",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"inputs": [],
	"name": "Admin",
	"outputs": [{
		"internalType": "address",
		"name": "",
		"type": "address"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "int256[]",
		"name": "x",
		"type": "int256[]"
	}, {
		"internalType": "int256[]",
		"name": "y",
		"type": "int256[]"
	}, {
		"internalType": "address[]",
		"name": "_a",
		"type": "address[]"
	}, {
		"internalType": "uint256[]",
		"name": "_price",
		"type": "uint256[]"
	}, {
		"internalType": "string",
		"name": "_cityname",
		"type": "string"
	}],
	"name": "AssignToken",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "int256",
		"name": "_x",
		"type": "int256"
	}, {
		"internalType": "int256",
		"name": "_y",
		"type": "int256"
	}],
	"name": "CheckOwner",
	"outputs": [{
		"internalType": "address",
		"name": "",
		"type": "address"
	}, {
		"internalType": "string",
		"name": "",
		"type": "string"
	}, {
		"components": [{
			"internalType": "int256",
			"name": "xaxis",
			"type": "int256"
		}, {
			"internalType": "int256",
			"name": "yaxis",
			"type": "int256"
		}, {
			"internalType": "string",
			"name": "cityname",
			"type": "string"
		}],
		"internalType": "struct Vision.ParselData",
		"name": "",
		"type": "tuple"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "string",
		"name": "_cname",
		"type": "string"
	}],
	"name": "GetData",
	"outputs": [{
		"internalType": "uint256[]",
		"name": "",
		"type": "uint256[]"
	}, {
		"internalType": "uint256[]",
		"name": "",
		"type": "uint256[]"
	}, {
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}, {
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "address",
		"name": "_contract",
		"type": "address"
	}, {
		"internalType": "address",
		"name": "user",
		"type": "address"
	}],
	"name": "Givemetoken",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "address",
		"name": "_a",
		"type": "address"
	}, {
		"internalType": "uint256",
		"name": "_v",
		"type": "uint256"
	}],
	"name": "Givemetoken",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "bool",
		"name": "_b",
		"type": "bool"
	}],
	"name": "RemoveLock",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"inputs": [],
	"name": "allunlock",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "address",
		"name": "to",
		"type": "address"
	}, {
		"internalType": "uint256",
		"name": "tokenId",
		"type": "uint256"
	}],
	"name": "approve",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "int256",
		"name": "",
		"type": "int256"
	}, {
		"internalType": "int256",
		"name": "",
		"type": "int256"
	}],
	"name": "axis",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "address",
		"name": "owner",
		"type": "address"
	}],
	"name": "balanceOf",
	"outputs": [{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "address",
		"name": "_admin",
		"type": "address"
	}],
	"name": "changeAdmin",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "uint256",
		"name": "_days1",
		"type": "uint256"
	}, {
		"internalType": "uint256",
		"name": "_days5",
		"type": "uint256"
	}, {
		"internalType": "uint256",
		"name": "_days2",
		"type": "uint256"
	}],
	"name": "changeLockDays",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "uint256",
		"name": "_amount",
		"type": "uint256"
	}],
	"name": "changePlateFormFee",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "uint256[]",
		"name": "_tokenid",
		"type": "uint256[]"
	}, {
		"internalType": "uint256",
		"name": "_time",
		"type": "uint256"
	}],
	"name": "changeid",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "address",
		"name": "_address",
		"type": "address"
	}],
	"name": "changeplateformFee",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "address",
		"name": "_usdt",
		"type": "address"
	}],
	"name": "changetokenaddress",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "string",
		"name": "",
		"type": "string"
	}],
	"name": "city",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "string",
		"name": "",
		"type": "string"
	}],
	"name": "code_",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "uint256",
		"name": "_tokenId",
		"type": "uint256"
	}],
	"name": "creatorOf",
	"outputs": [{
		"internalType": "address",
		"name": "",
		"type": "address"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "uint256",
		"name": "tokenId",
		"type": "uint256"
	}],
	"name": "getApproved",
	"outputs": [{
		"internalType": "address",
		"name": "",
		"type": "address"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "address",
		"name": "_contractaddress",
		"type": "address"
	}, {
		"internalType": "uint256",
		"name": "_amount",
		"type": "uint256"
	}, {
		"internalType": "uint256",
		"name": "_time",
		"type": "uint256"
	}, {
		"internalType": "int256",
		"name": "x",
		"type": "int256"
	}, {
		"internalType": "int256",
		"name": "y",
		"type": "int256"
	}, {
		"internalType": "string",
		"name": "code",
		"type": "string"
	}],
	"name": "getMessageHash",
	"outputs": [{
		"internalType": "bytes32",
		"name": "",
		"type": "bytes32"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "address",
		"name": "owner",
		"type": "address"
	}, {
		"internalType": "address",
		"name": "operator",
		"type": "address"
	}],
	"name": "isApprovedForAll",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "string",
		"name": "",
		"type": "string"
	}],
	"name": "iscodeuse",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}],
	"name": "isnative",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [],
	"name": "lasttime",
	"outputs": [{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [],
	"name": "lockdays1",
	"outputs": [{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [],
	"name": "lockdays2",
	"outputs": [{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [],
	"name": "lockdays5",
	"outputs": [{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "string",
		"name": "",
		"type": "string"
	}, {
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}],
	"name": "mintedamountblock",
	"outputs": [{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "string",
		"name": "",
		"type": "string"
	}],
	"name": "mintedblock",
	"outputs": [{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "uint256",
		"name": "_amount",
		"type": "uint256"
	}, {
		"internalType": "uint256",
		"name": "_time",
		"type": "uint256"
	}, {
		"internalType": "string",
		"name": "code",
		"type": "string"
	}, {
		"internalType": "bytes",
		"name": "signature",
		"type": "bytes"
	}, {
		"internalType": "int256",
		"name": "_x",
		"type": "int256"
	}, {
		"internalType": "int256",
		"name": "_y",
		"type": "int256"
	}, {
		"internalType": "string",
		"name": "_cityname",
		"type": "string"
	}, {
		"internalType": "uint256",
		"name": "_usd",
		"type": "uint256"
	}],
	"name": "multibuyparsel",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"stateMutability": "payable",
	"type": "function"
}, {
	"inputs": [],
	"name": "name",
	"outputs": [{
		"internalType": "string",
		"name": "",
		"type": "string"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "uint256",
		"name": "_tokenId",
		"type": "uint256"
	}],
	"name": "nativeBuy",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"stateMutability": "payable",
	"type": "function"
}, {
	"inputs": [],
	"name": "nativecommision",
	"outputs": [{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "string[]",
		"name": "_nc",
		"type": "string[]"
	}, {
		"internalType": "uint256[]",
		"name": "_totalBlock",
		"type": "uint256[]"
	}],
	"name": "newcity",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"inputs": [],
	"name": "owner",
	"outputs": [{
		"internalType": "address",
		"name": "",
		"type": "address"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "uint256",
		"name": "tokenId",
		"type": "uint256"
	}],
	"name": "ownerOf",
	"outputs": [{
		"internalType": "address",
		"name": "",
		"type": "address"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}],
	"name": "parseldata",
	"outputs": [{
		"internalType": "int256",
		"name": "xaxis",
		"type": "int256"
	}, {
		"internalType": "int256",
		"name": "yaxis",
		"type": "int256"
	}, {
		"internalType": "string",
		"name": "cityname",
		"type": "string"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [],
	"name": "plateformFee",
	"outputs": [{
		"internalType": "address",
		"name": "",
		"type": "address"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "uint256",
		"name": "_tokenId",
		"type": "uint256"
	}],
	"name": "removeSell",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"inputs": [],
	"name": "renounceOwnership",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "address",
		"name": "from",
		"type": "address"
	}, {
		"internalType": "address",
		"name": "to",
		"type": "address"
	}, {
		"internalType": "uint256",
		"name": "tokenId",
		"type": "uint256"
	}],
	"name": "safeTransferFrom",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "address",
		"name": "from",
		"type": "address"
	}, {
		"internalType": "address",
		"name": "to",
		"type": "address"
	}, {
		"internalType": "uint256",
		"name": "tokenId",
		"type": "uint256"
	}, {
		"internalType": "bytes",
		"name": "data",
		"type": "bytes"
	}],
	"name": "safeTransferFrom",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "uint256[]",
		"name": "_tokenId",
		"type": "uint256[]"
	}, {
		"internalType": "uint256[]",
		"name": "_price",
		"type": "uint256[]"
	}, {
		"internalType": "address",
		"name": "_contract",
		"type": "address"
	}],
	"name": "sell",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}],
	"name": "sellDetails",
	"outputs": [{
		"internalType": "address",
		"name": "seller",
		"type": "address"
	}, {
		"internalType": "address",
		"name": "buyer",
		"type": "address"
	}, {
		"internalType": "uint256",
		"name": "price",
		"type": "uint256"
	}, {
		"internalType": "bool",
		"name": "isnative",
		"type": "bool"
	}, {
		"internalType": "bool",
		"name": "open",
		"type": "bool"
	}, {
		"internalType": "address",
		"name": "tcontract",
		"type": "address"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "address",
		"name": "operator",
		"type": "address"
	}, {
		"internalType": "bool",
		"name": "approved",
		"type": "bool"
	}],
	"name": "setApprovalForAll",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "bytes4",
		"name": "interfaceId",
		"type": "bytes4"
	}],
	"name": "supportsInterface",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [],
	"name": "symbol",
	"outputs": [{
		"internalType": "string",
		"name": "",
		"type": "string"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "uint256",
		"name": "tokenId",
		"type": "uint256"
	}],
	"name": "tokenURI",
	"outputs": [{
		"internalType": "string",
		"name": "",
		"type": "string"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}],
	"name": "tokenlock",
	"outputs": [{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [],
	"name": "totalSupply",
	"outputs": [{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "string",
		"name": "",
		"type": "string"
	}],
	"name": "totalblock",
	"outputs": [{
		"internalType": "uint256",
		"name": "",
		"type": "uint256"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "address",
		"name": "from",
		"type": "address"
	}, {
		"internalType": "address",
		"name": "to",
		"type": "address"
	}, {
		"internalType": "uint256",
		"name": "tokenId",
		"type": "uint256"
	}],
	"name": "transferFrom",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "address",
		"name": "newOwner",
		"type": "address"
	}],
	"name": "transferOwnership",
	"outputs": [],
	"stateMutability": "nonpayable",
	"type": "function"
}, {
	"inputs": [],
	"name": "usdt",
	"outputs": [{
		"internalType": "address",
		"name": "",
		"type": "address"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"inputs": [{
		"internalType": "address",
		"name": "_signer",
		"type": "address"
	}, {
		"internalType": "address",
		"name": "_contractaddress",
		"type": "address"
	}, {
		"internalType": "uint256",
		"name": "_amount",
		"type": "uint256"
	}, {
		"internalType": "uint256",
		"name": "_time",
		"type": "uint256"
	}, {
		"internalType": "string",
		"name": "code",
		"type": "string"
	}, {
		"internalType": "int256",
		"name": "x",
		"type": "int256"
	}, {
		"internalType": "int256",
		"name": "y",
		"type": "int256"
	}, {
		"internalType": "bytes",
		"name": "signature",
		"type": "bytes"
	}],
	"name": "verify",
	"outputs": [{
		"internalType": "bool",
		"name": "",
		"type": "bool"
	}],
	"stateMutability": "view",
	"type": "function"
}, {
	"stateMutability": "payable",
	"type": "receive"
}]
export {
	CONTRACT_ADDRESS,
	ABI,
	RPC_URL,
	BLOCK_EXPLORE_URL,
	APPROVE_CONTRACT_ADDRESS,
	APPROVE_CONTRACT_ABI
}