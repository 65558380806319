import React from 'react'

export default function CompletedRecord({ completedRecords }) {

  return (
    <div className='admin_list_card added_rec table-responsive'>
      <table className="table ">
        <thead className='added_rec'>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Date</th>
            <th scope="col">X-Coord</th>
            <th scope="col">Y-Coord</th>
            <th scope="col">Price (BNB)</th>
            <th scope="col">Price (USDT)</th>
            <th scope="col">Wallet Address</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {
            completedRecords ?
              completedRecords.length ?
                completedRecords.map((i, index) =>
                  <tr key={index}>
                    <td>{i.id}</td>
                    <td>{i.ondate}</td>
                    <td>{i.xaxis}</td>
                    <td>{i.yaxis}</td>
                    <td>{i.amount}</td>
                    <td>${i.usdamount}</td>
                    <td>{i.walletaddress}</td>
                    <td className='text-success'>{'Completed'}</td>
                  </tr>
                )
                :
                <tr className='text-center'>
                  <td colSpan={8} className='no_data'>No Data Found</td>
                </tr>
              :
              <tr className='text-center'>
                <td colSpan={8} className='no_data'>Loading...</td>
              </tr>
          }
        </tbody>
      </table>
    </div>
  )
}
