import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../commponet/Header";
import useContract from "../useContract";
import useToast from "../useToast";
import axios from "axios";
import Map from "../commponet/Map";
import Footer from "../commponet/Footer";

export default function Home() {
  const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

  const { active } = useWeb3React();
  const [cords, setCords] = useState({
    x: 0,
    y: 0,
  });
  const [owner, setOwner] = useState(ZERO_ADDRESS);
  const [type, setType] = useState("");
  const [mintTx, setMintTx] = useState();
  const [loading, setLoading] = useState({ fetchData: false, mint: false });
  const [historyData, setHistoryData] = useState([]);
  const [historyDataAll, setHistoryDataAll] = useState([]);
  const [latestPrice, setLatestPrice] = useState(0);
  const [bnbUsdPrice, setBnbUsdPrice] = useState(0);
  const [landName, setLandName] = useState("");
  const [mintTime, setMintTime] = useState(null);

  const [selectedParcels, setSelectedParcels] = useState([]);
  const [startTime, setStartTime] = useState("");
  const [afterDays, setAfterDays] = useState("");
  const [incrementAmount, setIncrementAmount] = useState(0);
  const [states, setStates] = useState({
    monthlyparcelamount: 0,
    totalparcelamount: 0,
    weeklyparcelamount: 0,
  });

  // const [removebox, setRemovebox] = useState([]);

  const {
    mint,
    getCordData,
    getHistory,
    getHistoryAll,
    getLatestPrice,
    getStartTime,
    getAafterchangerate,
    getUsd,
    getIncrementAmount,
    getCityData,
  } = useContract();

  // timer
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);

  const loading0 = (num) => {
    return num < 10 ? "0" + num : num;
  };

  function getTimeUntil(deadline) {
    const time = Date.parse(deadline) - Date.parse(new Date());
    if (time < 0) {
      setDays(0);
      setHours(0);
      setMinutes(0);
      setSeconds(0);
    } else {
      const seconds = Math.floor((time / 1000) % 60);
      const minutes = Math.floor((time / 1000 / 60) % 60);
      const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
      const days = Math.floor(time / (1000 * 60 * 60 * 24));
      setDays(days);
      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds);
    }
  }

  useEffect(() => {
    if (startTime && afterDays) {
      let endtime = parseInt(afterDays) * 86400 + parseInt(startTime);
      let d = new Date(endtime * 1000);
      let interval = setInterval(() => getTimeUntil(d), 1000);
      return () => clearInterval(interval);
    }
  }, [startTime, afterDays]);

  const getInitContractData = async () => {
    const t = await getStartTime();
    setStartTime(t);
    const d = await getAafterchangerate();
    setAfterDays(d);
    const u = await getUsd();
    const i = await getIncrementAmount();

    setIncrementAmount(parseInt(u) + parseInt(i));
  };

  const getStates = async () => {
    try {
      const response = await axios.get("https://aojus.com/getbuyparcelamount");

      setStates({
        ...states,
        monthlyparcelamount: response.data.result.monthlyparcelamount,
        totalparcelamount: response.data.result.totalparcelamount,
        weeklyparcelamount: response.data.result.weeklyparcelamount,
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getInitContractData();
    getStates();
  }, []);

  const navigate = useNavigate();
  const { toastSuccess } = useToast();

  const getBnbUsdPrice = async () => {
    try {
      const res = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd"
      );
      setBnbUsdPrice(res.data.binancecoin.usd);
    } catch (e) { }
  };

  useEffect(() => {
    getBnbUsdPrice();
  }, []);

  // const getParcelData = async () => {
  //   try {
  //     if (cords.x || cords.y) {
  //       setLoading({ ...loading, fetchData: true });
  //       let x = !cords.x ? 0 : cords.x;
  //       let y = !cords.y ? 0 : cords.y;
  //       setTimeout(async () => {
  //         let cordData = await getCordData(x, y);
  //         let price = await getLatestPrice();

  //         setLatestPrice(price / 10 ** 18);
  //         setOwner(cordData["o"]);
  //         setType(cordData["t"]);
  //         setLandName(cordData["n"]);
  //         setMintTime(cordData["ti"]);
  //         setLoading({ ...loading, fetchData: false });
  //       });
  //     }
  //   } catch (e) {
  //     setLoading({ ...loading, fetchData: false });
  //   }
  // };

  // const mintCord = async () => {
  //   try {
  //     if (!active) {
  //       navigate("/login");
  //     } else {
  //       setLoading({ ...loading, mint: true });
  //       let r = await mint("100000000000000000", cords.x, cords.y);
  //       setMintTx(r);
  //       setLoading({ ...loading, mint: false });

  //       if (r) {
  //         toastSuccess("Minted Successfully");
  //       }
  //     }
  //   } catch (e) {
  //     setLoading({ ...loading, mint: false });
  //   }
  // };

  // const getHistoryData = async () => {
  //   let dataList = [];
  //   let r = await getHistory();
  //   for (var i = 0; i < r[0].length; i++) {
  //     let temp = {};
  //     temp.time = r[1][i];
  //     temp.location = { x: r[2][i][0], y: r[2][i][1] };
  //     dataList.push(temp);
  //   }
  //   setHistoryData(dataList);
  // };

  // const getHistoryDataAll = async () => {
  //   let dataList = [];
  //   let r = await getHistoryAll();
  //   for (var i = 0; i < r[0].length; i++) {
  //     let temp = {};
  //     temp.time = r[1][i];
  //     temp.location = { x: r[2][i][0], y: r[2][i][1] };
  //     // //console.log(temp.location);
  //     dataList.push(temp);
  //   }
  //   setHistoryDataAll(dataList);
  // };

  // const getCordDetail = async () => {
  //   let dataList = [];
  //   let r = await getHistoryAll();
  // };

  // useEffect(() => {
  //   getHistoryDataAll();
  // }, [mintTx]);

  // useEffect(() => {
  //   if (active) {
  // getHistoryData();
  // getHistoryDataAll();
  //   }
  // }, [active, mintTx]);

  // useEffect(() => {
  // getParcelData();
  // getCordDetail();
  // }, [cords.x, cords.y, mintTx]);

  // const updateCords = (x, y) => {
  //   setCords({ ...cords, x: x, y: y });
  // };

  return (
    <>
      <div className="main_page game_zone_bg">
        <Header />
        <div className="px-0 px-md-5 py-4 ">
          <div className="game_zone position-relative">
            <div className="container">
              <div className="overflow-hidden  radius_10 d-block map_border">
                <div id="container">
                  <Map
                    selectedParcels={selectedParcels}
                    // updateCords={updateCords}
                    latestPrice={latestPrice}
                    bnbUsdPrice={bnbUsdPrice}
                  />
                </div>
                <p id="showCoords" />
              </div>
            </div>
          </div>
          {/* PARCEL DETAIL DESKTOP VIEW */}
          <div className="container d-sm-block d-none">
            <div className="detail_card">
              <div className="note">
                <p>Note : All colors shown here represent objects in the map</p>
              </div>

              <div className="row">
                <div className="col-lg-5">
                  <h3>Sellable Parcels</h3>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="volume_detail">
                        <div className="d-flex detail_name justify-content-between map_detail_01 ">
                          <div className="align-items-center d-flex">
                            <span className="common_plot"></span>
                            <h5 className="m-0 text-dark">Common Land</h5>
                          </div>
                          <h6>$200</h6>
                        </div>
                        <div className="d-flex detail_name justify-content-between map_detail_01">
                          <div className="align-items-center d-flex">
                            <span className="regular_plot"></span>
                            <h5 className="m-0 text-dark">Regular Land</h5>
                          </div>
                          <h6>$1000</h6>
                        </div>
                        <div className="d-flex detail_name justify-content-between map_detail_01 mb-0">
                          <div className="align-items-center d-flex">
                            <span className="city_side"></span>
                            <h5 className="m-0 text-dark">City Side Land</h5>
                          </div>
                          <h6>$4000</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="volume_detail">
                        <div className="d-flex detail_name justify-content-between map_detail_01 ">
                          <div className="align-items-center d-flex">
                            <span className="artist_land"></span>
                            <h5 className="m-0 text-dark">Artist Land</h5>
                          </div>
                          <h6>$10000</h6>
                        </div>
                        <div className="d-flex detail_name justify-content-between map_detail_01">
                          <div className="align-items-center d-flex">
                            <span className="celeb_land"></span>
                            <h5 className="m-0 text-dark">Celeb Land</h5>
                          </div>
                          <h6>$50000</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <h3>Non-Sellable Parcels</h3>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="volume_detail">
                        <div className="d-flex detail_name justify-content-between map_detail_01 ">
                          <div className="align-items-center d-flex">
                            <span className="road"></span>
                            <h5 className="m-0 text-dark">Road</h5>
                          </div>
                          <h6>-</h6>
                        </div>
                        <div className="d-flex detail_name justify-content-between map_detail_01">
                          <div className="align-items-center d-flex">
                            <span className="water"></span>
                            <h5 className="m-0 text-dark">Water</h5>
                          </div>
                          <h6>-</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="volume_detail">
                        <div className="d-flex detail_name justify-content-between map_detail_01 ">
                          <div className="align-items-center d-flex">
                            <span className="forest"></span>
                            <h5 className="m-0 text-dark">Forest</h5>
                          </div>
                          <h6>-</h6>
                        </div>
                        <div className="d-flex detail_name justify-content-between map_detail_01">
                          <div className="align-items-center d-flex">
                            <span className="dessert"></span>
                            <h5 className="m-0 text-dark">Dessert</h5>
                          </div>
                          <h6>-</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <h3>Other Parcels</h3>
                  <div className="volume_detail">
                    <div className="d-flex detail_name justify-content-between map_detail_01 ">
                      <div className="align-items-center d-flex">
                        <span className="selected"></span>
                        <h5 className="m-0 text-dark">Selected</h5>
                      </div>
                      <h6>-</h6>
                    </div>
                    <div className="d-flex detail_name justify-content-between map_detail_01">
                      <div className="align-items-center d-flex">
                        <span className="sold"></span>
                        <h5 className="m-0 text-dark">Sold</h5>
                      </div>
                      <h6>-</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* PARCEL DETAIL MOBILE VIEW */}
          <div className="container d-sm-none d-block">
            <div className="detail_card">
              <div className="note ps-1">
                <p>Note : All colors shown here represent objects in the map</p>
              </div>
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Sellable Parcels
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="volume_detail">
                            <div className="d-flex detail_name justify-content-between map_detail_01 ">
                              <div className="align-items-center d-flex">
                                <span className="common_plot"></span>
                                <h5 className="m-0 text-dark">Common Land</h5>
                              </div>
                              <h6>$200</h6>
                            </div>
                            <div className="d-flex detail_name justify-content-between map_detail_01">
                              <div className="align-items-center d-flex">
                                <span className="regular_plot"></span>
                                <h5 className="m-0 text-dark">Regular Land</h5>
                              </div>
                              <h6>$1000</h6>
                            </div>
                            <div className="d-flex detail_name justify-content-between map_detail_01 mb-0">
                              <div className="align-items-center d-flex">
                                <span className="city_side"></span>
                                <h5 className="m-0 text-dark">City Side Land</h5>
                              </div>
                              <h6>$4000</h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="volume_detail">
                            <div className="d-flex detail_name justify-content-between map_detail_01 ">
                              <div className="align-items-center d-flex">
                                <span className="artist_land"></span>
                                <h5 className="m-0 text-dark">Artist Land</h5>
                              </div>
                              <h6>$10000</h6>
                            </div>
                            <div className="d-flex detail_name justify-content-between map_detail_01">
                              <div className="align-items-center d-flex">
                                <span className="celeb_land"></span>
                                <h5 className="m-0 text-dark">Celeb Land</h5>
                              </div>
                              <h6>$50000</h6>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Non-Sellable Parcels
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="volume_detail">
                            <div className="d-flex detail_name justify-content-between map_detail_01 ">
                              <div className="align-items-center d-flex">
                                <span className="road"></span>
                                <h5 className="m-0 text-dark">Road</h5>
                              </div>
                              <h6>-</h6>
                            </div>
                            <div className="d-flex detail_name justify-content-between map_detail_01">
                              <div className="align-items-center d-flex">
                                <span className="water"></span>
                                <h5 className="m-0 text-dark">Water</h5>
                              </div>
                              <h6>-</h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="volume_detail">
                            <div className="d-flex detail_name justify-content-between map_detail_01 ">
                              <div className="align-items-center d-flex">
                                <span className="forest"></span>
                                <h5 className="m-0 text-dark">Forest</h5>
                              </div>
                              <h6>-</h6>
                            </div>
                            <div className="d-flex detail_name justify-content-between map_detail_01">
                              <div className="align-items-center d-flex">
                                <span className="dessert"></span>
                                <h5 className="m-0 text-dark">Dessert</h5>
                              </div>
                              <h6>-</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Other Parcels
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="volume_detail">
                        <div className="d-flex detail_name justify-content-between map_detail_01 ">
                          <div className="align-items-center d-flex">
                            <span className="selected"></span>
                            <h5 className="m-0 text-dark">Selected</h5>
                          </div>
                          <h6>-</h6>
                        </div>
                        <div className="d-flex detail_name justify-content-between map_detail_01">
                          <div className="align-items-center d-flex">
                            <span className="sold"></span>
                            <h5 className="m-0 text-dark">Sold</h5>
                          </div>
                          <h6>-</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
