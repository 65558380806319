import React, { useState } from 'react'
import { useWeb3React } from '@web3-react/core';
import { ABI, CONTRACT_ADDRESS } from '../constss';
import { loadContract } from '../utils';
import useToast from '../useToast';
import axios from 'axios';
import { CircularProgress } from '@mui/material';

export default function PendingRecord({ pendingRecords }) {

  const [loading, setLoading] = useState(false);
  const [loaderIndex, setLoaderIndex] = useState(null);
  const { account, connector } = useWeb3React();
  const { toastError, toastSuccess } = useToast();

  const convertPrice = (value) => {
    const price = (value * 10 ** 6).toLocaleString("fullwide", { useGrouping: false });
    return price;
  }

  const onCallContract = async (item) => {
    // console.log('iiii ', item)
    if (!account) {
      toastError('Please connect your wallet');
      return;
    }
    setLoading(true);
    try {
      const provider = await connector.getProvider();
      // console.log('provider', provider);
      let load_contract = loadContract(provider, ABI, CONTRACT_ADDRESS);
      const res_assign_token = await load_contract.methods.AssignToken(
        [item.xaxis],
        [item.yaxis],
        [item.walletaddress],
        [item.amount],
        'celebverse'
      ).send({ from: account });
      setLoading(false);
      // console.log('res_assign_token', res_assign_token);
      try {
        const res_api = await axios.post('https://backend.celebverse.live/buy_land_entry',
          {
            "tranhash": res_assign_token.transactionHash,
            "address": item.walletaddress,
            "xaxis": item.xaxis,
            "yaxis": item.yaxis,
            "amount": item.amount,
            "usdtamt": item.amount,
            "tokenid": res_assign_token.events.onMint.returnValues.TokenId,
            "coupencode": '',
            "iscelebverse": 1
          }
        )
        // console.log('res_api', res_api)
      } catch (err) {
        console.log('Error.API-buyland', err);
      }
      setLoading(false);
      toastSuccess('Parcel buy successfully');
    } catch (e) {
      setLoading(false);
      toastError(e.message)
      console.log('Error.buy-land', e);
    }
  }

  return (
    <div className='admin_list_card added_rec table-responsive'>
      <table className="table ">
        <thead className='added_rec pb-2'>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Date</th>
            <th scope="col">X-Coord</th>
            <th scope="col">Y-Coord</th>
            <th scope="col">Price (BNB)</th>
            <th scope="col">Price (USDT)</th>
            <th scope="col">Wallet Address</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {
            pendingRecords ?
              pendingRecords.length ?
                pendingRecords.map((i, index) =>
                  <tr key={index}>
                    <td>{i.id}</td>
                    <td>{i.ondate}</td>
                    <td>{i.xaxis}</td>
                    <td>{i.yaxis}</td>
                    <td>{i.amount}</td>
                    <td>${i.usdamount}</td>
                    <td>{i.walletaddress}</td>
                    <td>
                      <button className="wallet_btn action_btn" onClick={() => { onCallContract(i); setLoaderIndex(index) }}>
                        {
                          loading && loaderIndex == index ?
                            <div className='d-flex align-items-center'><CircularProgress className='mx-2' size={16} style={{ color: '#ffffff' }} />Loading...</div>
                            :
                            'Call Contract'
                        }
                      </button>
                    </td>
                  </tr>
                )
                :
                <tr className='text-center'>
                  <td colSpan={8} className='no_data'>No Data Found</td>
                </tr>
              :
              <tr className='text-center'>
                <td colSpan={8} className='no_data'>Loading...</td>
              </tr>
          }
        </tbody>
      </table>
    </div>
  )
}
