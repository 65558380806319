import axios from 'axios';
import React from 'react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { setCookie } from '../commponet/Cookie';
import { ROOT_URL } from '../constss';
import useToast from '../useToast';

export default function AdminLogin() {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [validation, setValidation] = useState({ username: false, password: false });
  const [loading, setLoading] = useState(false);
  const { toastError, toastSuccess } = useToast();
  const navigate = useNavigate();

  const onLogin = () => {
    if (!username) {
      setValidation({ ...validation, username: true });
      return;
    }
    if (password.length < 8) {
      setValidation({ ...validation, password: true });
      return;
    }
    setLoading(true);
    axios({
      method: 'post',
      url: `${ROOT_URL}/check_admin_login`,
      data: {
        username: username,
        password: password
      }
    }).then(res => {
      setLoading(false);
      // console.log('LOGING res ', res);
      if (res.data.status == "Success") {
        toastSuccess(res.data.msg);
        setCookie('celeb-admin', true);
        navigate('/admin')
      } else {
        toastError(res.data.msg);
      }
    }
    ).catch(err => {
      setLoading(false);
      console.log('Error.admin-login', err)
    });
  }

  return (
    <div>
      <div className="header position-relative">
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              <div className=" navbar-collapse d-flex align-items-center justify-content-between">
                <div className="nav_flex">
                  <Link to={"/admin"} className="navbar-brand me-1">
                    <img src={"/assets/img/logo/logo.svg"} alt="logo" />
                  </Link>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="page_height parcel_page admin_height d-flex align-items-center">
        <div className="container">
          <div className="usercard m-0">
            <div className="card position-relative card_shadow admin_card">
              <div className="card-body py-4 bg-transparent">
                <h3 className='text-align-center mb-4'>Admin Login</h3>
                <div className="row">
                  <div className='col-12'>
                    <input
                      type={'text'}
                      placeholder={'Username'}
                      className="coune_code py-2 mt-3"
                      autoComplete="off"
                      value={username}
                      onChange={(event) => { setUsername(event.target.value); setValidation({ ...validation, username: false }) }}
                    />
                    {
                      validation.username &&
                      <div className='error_msg'>{username ? 'Invalid username' : 'Please enter username'}</div>
                    }
                    <input
                      type={'password'}
                      placeholder={'Password'}
                      className="coune_code py-2 mt-3"
                      value={password}
                      onChange={(event) => { setPassword(event.target.value); setValidation({ ...validation, password: false }) }}
                    />
                    {
                      validation.password &&
                      <div className='error_msg'>{password ? 'Password must contain 8 charactors' : 'Please enter password'}</div>
                    }
                  </div>
                </div>
                <div className='row align-items-center'>
                  <div className="property text-center text-md-end">
                    <button className="wallet_btn mt-3 w-100" onClick={() => onLogin()}>{loading ? 'Loading...' : 'Login'}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
