import { BLOCK_EXPLORE_URL, RPC_URL } from "./constss"

export const setupNetwork = async (externalProvider) => {
    const provider = externalProvider || window.ethereum
    const chainId = 56
    const rpc = RPC_URL
    const chainName = 'Binance Smart Chain Mainnet'
    const currName = 'BNB'
    const currSymbol = 'BNB'
    const currDecimal = 18
    const blockExplorerUrl = BLOCK_EXPLORE_URL
    if (provider) {
        try {
            await provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${chainId.toString(16)}` }],
            })
            return true
        } catch (switchError) {
            if ((switchError)?.code === 4902) {
                try {
                    await provider.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: `0x${chainId.toString(16)}`,
                                chainName: chainName,
                                nativeCurrency: {
                                    name: currName,
                                    symbol: currSymbol,
                                    decimals: currDecimal,
                                },
                                rpcUrls: [rpc],
                                blockExplorerUrls: [`${blockExplorerUrl}/`],
                            },
                        ],
                    })
                    return true
                } catch (error) {
                    console.error('Failed to setup the network in Metamask:', error)
                    return false
                }
            }
            return false
        }
    } else {
        console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
        return false
    }
}

