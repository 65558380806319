import React from 'react'
import { Link } from 'react-router-dom'
import '../assets/css/footer.css';
import { BLOCK_EXPLORE_URL, CONTRACT_ADDRESS } from '../constss';

export default function
    () {
    return (
        <>
            <footer className="footer">
                <div className="container-fluid">
                    <div className="row text-center justify-content-center text-white">
                        <div className="col-lg-7 col-sm-10">
                            <a href="#" className="footer_logo"><img src="/assets/img/logo/logo.svg" alt="logo" /></a>
                            <p className="footer_link mb-2">
                                Copyrights ©Celebverse2022. All rights reserved.
                            </p>
                            <p className="footer_link">
                                Smart Contract Address :  <a href={`${BLOCK_EXPLORE_URL}/address/${CONTRACT_ADDRESS}`} target="_blank"> {CONTRACT_ADDRESS}</a>
                            </p>
                            <div className="social">
                                <span>
                                    <a href="#">
                                        <i className="fab fa-facebook-f  text-white" aria-hidden="true"></i>
                                    </a>
                                </span>
                                <span>
                                    <a href="#">
                                        <i className="fab fa-twitter  text-white" aria-hidden="true"></i>
                                    </a>
                                </span>
                                <span>
                                    <a href="#">
                                        <i className="fab fa-instagram text-white" aria-hidden="true"></i>
                                </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
