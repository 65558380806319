import { InjectedConnector } from "@web3-react/injected-connector"
import { WalletConnectConnector } from "@web3-react/walletconnect-connector"
import Web3 from 'web3'
import { RPC_URL } from "./constss"

const loadContract = (provider, abi, address) => {
    const w3 = new Web3(provider)
    return new w3.eth.Contract(abi, address)
}

const shortenAddress = (address) => {
    return `${address.substring(0, 8)}...${address.substring(address.length - 6)}`
}

const injected = new InjectedConnector({
    supportedChainIds: [56],
})

const POLLING_INTERVAL = 25000
const walletconnect = new WalletConnectConnector({
    rpc: { 56: RPC_URL },
    bridge: "https://bridge.walletconnect.org",
    pollingInterval: POLLING_INTERVAL,
})

const getDuration = (tt) => {
    return Math.floor((new Date().getTime() - tt * 1000) / (1000 * 3600 * 24))
}


export {
    loadContract,
    injected,
    walletconnect,
    shortenAddress,
    getDuration
}